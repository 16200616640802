@charset "UTF-8";
/**
 * [Table of contents]
 *   1 Base
 *     1.1 Reset Styles
 *     1.2 Context Styles
 *     1.3 Page layout
 *     1.4 Offsets
 *   2 Utilities
 *     2.1 Text styling
 *     2.2 Row spacing
 *     2.3 Sections
 *     2.4 Grid modules
 *     2.5 Backgrounds
 *     2.6 Utilities custom
 *   3 Components
 *     3.1 Typography
 *     3.2 Brand
 *     3.3 Links
 *     3.4 Blocks
 *     3.5 Boxes
 *     3.6 Groups
 *     3.7 Responsive units
 *     3.8 Lists
 *     3.9 Images
 *     3.10 Icons
 *     3.11 Tables custom
 *     3.12 Dividers
 *     3.13 Buttons
 *     3.14 Forms
 *     3.15 Posts
 *     3.16 Quotes
 *     3.17 Thumbnails
 *     3.18 Breadcrumbs
 *     3.19 Pagination custom
 *     3.20 Snackbars
 *     3.21 Footers
 *   4 Plugins
 *     4.1 Animate
 *     4.2 Preloader
 *     4.3 ToTop
 *     4.4 RD Navbar
 *     4.5 Swiper
 *     4.6 Google Map
 *     4.7 Search Results
 *     4.8 Isotope
 *     4.9 Nav custom
 *     4.10 Panel custom
 *     4.11 Tooltop Custom
 *     4.12 Counter
 *     4.13 Progress Bars
 *     4.14 Progress Circle
 *     4.15 Countdown
 *     4.16 Owl Carousel
 *     4.17 Lightgallery
 *     4.18 Material Parallax
 *     4.19 Bootstrap Modal
 *     4.20 Select 2 v4
 */
/** @group Base */
/** @section Reset Styles*/
a:focus,
button:focus {
  outline: none !important;
}

button::-moz-focus-inner {
  border: 0;
}

*:focus {
  outline: none;
}

blockquote {
  padding: 0;
  margin: 0;
}

input,
button,
select,
textarea {
  outline: none;
}

label {
  margin-bottom: 0;
}

p {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li,
ol li {
  display: block;
}

dl {
  margin: 0;
}

dt,
dd {
  line-height: inherit;
}

dt {
  font-weight: inherit;
}

dd {
  margin-bottom: 0;
}

cite {
  font-style: normal;
}

form {
  margin-bottom: 0;
}

blockquote {
  padding-left: 0;
  border-left: 0;
}

address {
  margin-top: 0;
  margin-bottom: 0;
}

figure {
  margin-bottom: 0;
}

html p a:hover {
  text-decoration: none;
}

/** @section Context Styles */
.context-dark,
.bg-gray-700,
.bg-primary,
.context-dark h1,
.bg-gray-700 h1,
.bg-primary h1,
.context-dark h2,
.bg-gray-700 h2,
.bg-primary h2,
.context-dark h3,
.bg-gray-700 h3,
.bg-primary h3,
.context-dark h4,
.bg-gray-700 h4,
.bg-primary h4,
.context-dark h5,
.bg-gray-700 h5,
.bg-primary h5,
.context-dark h6,
.bg-gray-700 h6,
.bg-primary h6,
.context-dark [class^="heading-"],
.bg-gray-700 [class^="heading-"],
.bg-primary [class^="heading-"] {
  color: #0c1451;
}

/** @section Page layout */
.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  opacity: 0;
}

.page.fadeIn {
  animation-timing-function: ease-out;
}

.page.fadeOut {
  animation-timing-function: ease-in;
}

[data-x-mode] .page {
  opacity: 1;
}

html.boxed body {
  background: #edeff4 url(../assets/bg-pattern-boxed.png) repeat fixed;
}

@media (min-width: 1630px) {
  html.boxed .page {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 23px 0 rgba(1, 1, 1, 0.1);
  }
  html.boxed .rd-navbar-static {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
  html.boxed .rd-navbar-static.rd-navbar--is-stuck {
    max-width: 1600px;
    width: 100%;
    left: calc(50% - 1600px);
    right: calc(50% - 1600px);
  }
}

/** @section Offsets */
* + p {
  margin-top: 20px;
}

h2 + p,
.heading-2 + p {
  margin-top: 12px;
}

p + h2,
p + .heading-2 {
  margin-top: 15px;
}

p + p {
  margin-top: 12px;
}

img + p {
  margin-top: 15px;
}

h3 + img {
  margin-top: 42px;
}

* + .row {
  margin-top: 60px;
}

* + .big {
  margin-top: 20px;
}

* + .text-block {
  margin-top: 30px;
}

* + .button {
  margin-top: 30px;
}

.row + h3,
.row + .heading-3 {
  margin-top: 80px;
}

.container + .container {
  margin-top: 35px;
}

.row + .row {
  margin-top: 35px;
}

@media (min-width: 1200px) {
  * + .rd-form {
    margin-top: 30px;
  }
  .container + .container {
    margin-top: 60px;
  }
  .row + .row {
    margin-top: 60px;
  }
}

* + .btn-wrap {
  margin-top: 55px;
}

@media (max-width: 1199.98px) {
  * + .btn-wrap {
    margin-top: 15px;
  }
}

h3 + figure {
  margin-top: 60px;
}

.text-wrap {
  padding-top: 150px;
}

@media (max-width: 991.98px) {
  .text-wrap {
    padding-top: 75px;
  }
}

@media (max-width: 575.98px) {
  .text-wrap {
    padding-top: 40px;
  }
}

.list-wrap {
  padding-top: 65px;
}

@media (min-width: 1200px) {
  .list-wrap {
    padding-left: 20px;
  }
}

@media (max-width: 1199.98px) {
  .list-wrap {
    padding-top: 30px;
  }
}

/** @group Utilities */
/** @section Text styling */
.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

.text-underline {
  text-decoration: underline;
}

.text-strike {
  text-decoration: line-through;
}

.font-weight-thin {
  font-weight: 100;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-sbold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-ubold {
  font-weight: 900;
}

.text-spacing-0 {
  letter-spacing: 0;
}

.text-primary {
  color: #0c1451 !important;
}

.text-custom-blue {
  color: #494f7c;
}

/** @section Row spacing */
.row-0 {
  margin-bottom: 0px;
}

.row-0:empty {
  margin-bottom: 0;
}

.row-0 > * {
  margin-bottom: 0px;
}

.row-15 {
  margin-bottom: -15px;
}

.row-15:empty {
  margin-bottom: 0;
}

.row-15 > * {
  margin-bottom: 15px;
}

.row-20 {
  margin-bottom: -20px;
}

.row-20:empty {
  margin-bottom: 0;
}

.row-20 > * {
  margin-bottom: 20px;
}

.row-30 {
  margin-bottom: -30px;
}

.row-30:empty {
  margin-bottom: 0;
}

.row-30 > * {
  margin-bottom: 30px;
}

.row-40 {
  margin-bottom: -40px;
}

.row-40:empty {
  margin-bottom: 0;
}

.row-40 > * {
  margin-bottom: 40px;
}

.row-50 {
  margin-bottom: -50px;
}

.row-50:empty {
  margin-bottom: 0;
}

.row-50 > * {
  margin-bottom: 50px;
}

.row-60 {
  margin-bottom: -60px;
}

.row-60:empty {
  margin-bottom: 0;
}

.row-60 > * {
  margin-bottom: 60px;
}

.row-70 {
  margin-bottom: -70px;
}

.row-70:empty {
  margin-bottom: 0;
}

.row-70 > * {
  margin-bottom: 70px;
}

.row-80 {
  margin-bottom: -80px;
}

.row-80:empty {
  margin-bottom: 0;
}

.row-80 > * {
  margin-bottom: 80px;
}

@media (min-width: 992px) {
  .row-md-30 {
    margin-bottom: -30px;
  }
  .row-md-30:empty {
    margin-bottom: 0;
  }
  .row-md-30 > * {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .row-md-60 {
    margin-bottom: -60px;
  }
  .row-md-60:empty {
    margin-bottom: 0;
  }
  .row-md-60 > * {
    margin-bottom: 60px;
  }
  .row-md-80 {
    margin-bottom: -80px;
  }
  .row-md-80:empty {
    margin-bottom: 0;
  }
  .row-md-80 > * {
    margin-bottom: 80px;
  }
}

@media (max-width: 991.98px) {
  .row-sm-30 {
    margin-bottom: -30px;
  }
  .row-sm-30:empty {
    margin-bottom: 0;
  }
  .row-sm-30 > * {
    margin-bottom: 30px;
  }
}

@media (max-width: 991.98px) {
  .row-sm-50 {
    margin-bottom: -50px;
  }
  .row-sm-50:empty {
    margin-bottom: 0;
  }
  .row-sm-50 > * {
    margin-bottom: 50px;
  }
}

@media (max-width: 991.98px) {
  .row-sm-60 {
    margin-bottom: -60px;
  }
  .row-sm-60:empty {
    margin-bottom: 0;
  }
  .row-sm-60 > * {
    margin-bottom: 60px;
  }
}

/** @section Sections */
.section-xs {
  padding: 25px 0;
}

.section-sm,
.section-md,
.section-lg,
.section-xl {
  padding: 50px 0;
}

.section-collapse + .section-collapse {
  padding-top: 0;
}

.section-collapse:last-child {
  padding-bottom: 0;
}

html [class*="section-"].section-bottom-0 {
  padding-bottom: 0;
}

@media (max-width: 991.98px) {
  section.section-sm:first-of-type,
  section.section-md:first-of-type,
  section.section-lg:first-of-type,
  section.section-xl:first-of-type,
  section.section-xxl:first-of-type {
    padding-top: 40px;
  }
}

@media (min-width: 768px) {
  .section-sm {
    padding: 30px 0;
  }
  .section-md {
    padding: 45px 0;
  }
  .section-lg {
    padding: 65px 0;
  }
  .section-xl {
    padding: 75px 0;
  }
}

@media (min-width: 992px) {
  .section-md {
    padding: 70px 0 70px;
  }
  .section-lg {
    padding: 90px 0;
  }
  .section-xl {
    padding: 100px 0;
  }
}

@media (min-width: 1200px) {
  .section-lg {
    padding: 100px 0;
  }
}

.section-single {
  display: flex;
  text-align: center;
}

.section-single p {
  margin-left: auto;
  margin-right: auto;
}

.section-single * + .rights {
  margin-top: 35px;
}

@media (min-width: 992px) {
  .section-single * + .rights {
    margin-top: 60px;
  }
}

.section-single .rd-mailform-wrap {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.section-single * + .rd-mailform-wrap {
  margin-top: 20px;
}

.section-single-header {
  padding: calc(1em + 3vh) 0 calc(1em + 2vh);
}

.section-single-main {
  padding: calc(1em + 4vh) 0;
}

.section-single-footer {
  padding: calc(1em + 2vh) 0 calc(1em + 3vh);
}

.section-single-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
}

.section-single-inner > * {
  width: 100%;
}

@media (min-width: 1200px) {
  .container.wide {
    max-width: 1290px;
  }
}

.section-custom {
  padding-top: 130px;
}

.section-dv + .section-custom {
  padding-top: 100px;
}

.section-main-bunner {
  position: relative;
  overflow: hidden;
  padding: 80px 0 70px;
  background-color: #ffffff;
}

@media (min-width: 992px) {
  .section-main-bunner {
    min-height: 700px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 180px 0 120px;
  }
}

.section-main-bunner .main-bunner-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-position: right;
}

@media (min-width: 1200px) {
  .section-main-bunner .main-bunner-img {
    background-position: center;
  }
}

@media (max-width: 990px) {
  .section-main-bunner {
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.section-main-bunner .main-bunner-inner {
  position: relative;
  flex: 1;
  z-index: 1;
}

.section-main-bunner .main-bunner-inner * + h1 {
  margin-top: 30px;
}

.section-main-bunner .main-bunner-inner .bunner-location {
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-weight: 400;
}

.section-main-bunner .main-bunner-inner .bunner-location .icon {
  font-size: 30px;
  margin-right: 10px;
  vertical-align: top;
}

@media (min-width: 576px) {
  .section-main-bunner .main-bunner-inner .bunner-location .icon {
    vertical-align: middle;
  }
}

.section-main-bunner .main-bunner-inner .bunner-location a {
  display: inline-block;
}

.section-main-bunner.section-main-bunner-filter {
  background: #868e96;
}

.section-main-bunner.section-main-bunner-filter .main-bunner-img {
  opacity: 1;
}

.section-main-bunner.section-main-bunner-filter:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  background: linear-gradient(
    359.73deg,
    #3a092f 0.9%,
    rgba(60, 55, 79, 0.45) 107.43%
  );
  z-index: 1;
}

/** @section Grid modules */
.grid-demo {
  letter-spacing: 0;
  text-align: center;
}

.grid-demo p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-demo * + p {
  margin-top: 5px;
}

.grid-demo * + .row {
  margin-top: 20px;
}

.grid-demo .row + .row {
  margin-top: 0;
}

@media (min-width: 1200px) {
  .grid-demo {
    text-align: left;
  }
  .grid-demo p {
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  }
  .grid-demo * + p {
    margin-top: 25px;
  }
}

.grid-demo-bordered .row {
  border: 0 solid #d7d7d7;
  border-top-width: 1px;
}

.grid-demo-bordered .row:last-child {
  border-bottom-width: 1px;
}

.grid-demo-bordered [class*="col"] {
  padding: 5px 15px;
}

.grid-demo-bordered [class*="col"]:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  border-left: 1px solid #d7d7d7;
}

@media (min-width: 768px) {
  .grid-demo-bordered [class*="col"] {
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .grid-demo-bordered [class*="col"] {
    padding: 50px 50px 45px;
  }
}

.grid-demo-underlined .row {
  border-bottom: 1px solid #d7d7d7;
}

.grid-demo-underlined [class*="col"] {
  padding: 5px 15px;
}

@media (min-width: 768px) {
  .grid-demo-underlined [class*="col"] {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .grid-demo-underlined [class*="col"] {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

/** @section Backgrounds */
.context-dark .brand .brand-logo-dark,
.bg-gray-700 .brand .brand-logo-dark,
.bg-primary .brand .brand-logo-dark {
  display: none;
}

.context-dark .brand .brand-logo-light,
.bg-gray-700 .brand .brand-logo-light,
.bg-primary .brand .brand-logo-light {
  display: block;
}

.bg-default {
  background-color: #ffffff;
}

.bg-default:not([style*="background-"])
  + .bg-default:not([style*="background-"]) {
  padding-top: 0;
}

.bg-gray-100 {
  background-color: #edeff4;
}

.bg-gray-100:not([style*="background-"])
  + .bg-gray-100:not([style*="background-"]) {
  padding-top: 0;
}

.bg-gray-150 {
  background-color: #fafbfc;
}

.bg-gray-150:not([style*="background-"])
  + .bg-gray-150:not([style*="background-"]) {
  padding-top: 0;
}

.bg-gray-700 {
  background-color: #2c343b;
}

.bg-gray-700:not([style*="background-"])
  + .bg-gray-700:not([style*="background-"]) {
  padding-top: 0;
}

.bg-secondary {
  background-color: #feebe8;
}

.bg-secondary:not([style*="background-"])
  + .bg-secondary:not([style*="background-"]) {
  padding-top: 0;
}

.bg-primary + .bg-primary {
  padding-top: 0;
}

.bg-image {
  background-size: cover;
  background-position: center;
}

@media (min-width: 992px) {
  html:not(.tablet):not(.mobile) .bg-fixed {
    background-attachment: fixed;
  }
}

.bg-overlay-1 {
  position: relative;
}

.bg-overlay-1 > * {
  z-index: 1;
}

.bg-overlay-1::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 0;
  opacity: 1;
}

@media (min-width: 992px) {
  .bg-overlay-1::before {
    opacity: 0;
  }
}

/** @section Utilities custom */
.height-fill {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.height-fill > * {
  flex-grow: 1;
  flex-shrink: 0;
}

.object-inline {
  white-space: nowrap;
}

.object-inline > * {
  display: inline-block;
  min-width: 20px;
  vertical-align: top;
  white-space: normal;
}

.object-inline > * + * {
  margin-left: 5px;
}

.oh {
  position: relative;
  overflow: hidden;
}

.text-decoration-lines {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.text-decoration-lines-content {
  position: relative;
  display: inline-block;
  min-width: 170px;
  font-size: 13px;
  text-transform: uppercase;
}

.text-decoration-lines-content::before,
.text-decoration-lines-content::after {
  content: "";
  position: absolute;
  height: 1px;
  background: #d7d7d7;
  top: 50%;
  width: 100vw;
}

.text-decoration-lines-content::before {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.text-decoration-lines-content::after {
  right: 0;
  transform: translate3d(100%, 0, 0);
}

* + .text-decoration-lines {
  margin-top: 30px;
}

p.rights {
  font-size: 14px;
  color: #494f7c;
}

p.rights a {
  color: inherit;
}

p.rights a:hover {
  color: #0c1451;
}

[style*="z-index: 1000;"] {
  z-index: 1101 !important;
}

.text-block > * {
  margin-left: 0.125em;
  margin-right: 0.125em;
}

/** @group Components */
/** @section Typography */
body {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  line-height: 1.55556;
  font-weight: 400;
  color: #494f7c;
  background-color: #ffffff;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
[class^="heading-"] {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  color: #0c1451;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
[class^="heading-"] a {
  color: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
[class^="heading-"] a:hover {
  color: #0c1451;
}

h1,
.heading-1 {
  font-size: 50px;
  line-height: 1em;
}

@media (min-width: 1200px) {
  h1,
  .heading-1 {
    font-size: 80px;
    line-height: 1.1;
  }
}

h2,
.heading-2 {
  font-size: 35px;
  line-height: 1.2;
}

@media (min-width: 992px) {
  h2,
  .heading-2 {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  h2,
  .heading-2 {
    font-size: 60px;
    line-height: 1.1;
  }
}

h3,
.heading-3 {
  font-size: 24px;
  line-height: 1.5;
}

@media (min-width: 1200px) {
  h3,
  .heading-3 {
    font-size: 36px;
    line-height: 1.33333;
  }
}

h4,
.heading-4 {
  font-size: 22px;
  line-height: 1.5;
}

@media (min-width: 1200px) {
  h4,
  .heading-4 {
    font-size: 24px;
    line-height: 1.25;
  }
}

h5,
.heading-5 {
  font-size: 20px;
  line-height: 1.5;
}

@media (min-width: 1200px) {
  h5,
  .heading-5 {
    font-size: 18px;
    line-height: 1.44444;
  }
}

h6,
.heading-6 {
  font-size: 18px;
  line-height: 1.5;
}

@media (min-width: 1200px) {
  h6,
  .heading-6 {
    font-size: 16px;
    line-height: 1.5;
  }
}

small,
.small {
  display: block;
  font-size: 12px;
  line-height: 1.5;
}

mark,
.mark {
  padding: 3px 5px;
  color: #ffffff;
  background: #0c1451;
}

.fz-18 {
  font-size: 18px !important;
}

.big {
  font-size: 22px;
  line-height: 1.27273;
}

.lead {
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
}

code {
  padding: 3px 5px;
  border-radius: 0.2rem;
  font-size: 90%;
  color: #111111;
  background: #edeff4;
}

p [data-toggle="tooltip"] {
  padding-left: 0.25em;
  padding-right: 0.25em;
  color: #0c1451;
}

p [style*="max-width"] {
  display: inline-block;
}

::selection {
  background: #0c1451;
  color: #ffffff;
}

::-moz-selection {
  background: #0c1451;
  color: #ffffff;
}

.phone-link {
  font-size: 40px;
  font-weight: 700;
  color: #0c1451;
}

.phone-link:hover {
  color: #fe9b8e;
  transition: all 0.2s linear;
}

@media (max-width: 991.98px) {
  .phone-link {
    font-size: 30px;
  }

  .lead {
    margin-top: 20px;
  }
}

.phone-link-title {
  font-size: 40px;
  font-weight: 700;
  color: #0c1451;
  margin-right: 10px;
}

@media (max-width: 991.98px) {
  .phone-link-title {
    font-size: 30px;
  }
}

.phone-link-second a {
  font-size: 26px;
  font-weight: 700;
  color: #fe9b8e;
}

.phone-link-second a:hover {
  color: #0c1451;
  transition: all 0.2s linear;
}

.wrap .phone-link-second {
  margin-top: 10px;
}

* + .phone-wrap {
  margin-top: 85px;
}

@media (max-width: 1199.98px) {
  * + .phone-wrap {
    margin-top: 35px;
  }
}

@media (max-width: 991.98px) {
  * + .phone-wrap {
    margin-top: 15px;
  }
}

.title h2 {
  line-height: 66px;
}

@media (max-width: 991.98px) {
  .title h2 {
    line-height: 33px;
    font-size: 45px;
  }
}

.subtitle {
  font-size: 26px;
  color: #fe9b8e;
  font-weight: 500;
}

.subtitle-2 {
  color: #494f7c;
  font-size: 22px;
}

@media (max-width: 991.98px) {
  .subtitle-2 {
    font-size: 18px;
  }
}

.subtitle + .title {
  margin-top: 45px;
}

@media (max-width: 991.98px) {
  .subtitle + .title {
    margin-top: 0;
  }
}

@media (max-width: 1199.98px) {
  .subtitle + .title {
    margin-top: 15px;
  }
}

* + .subtitle-2 {
  margin-top: 22px;
}

@media (max-width: 1199.98px) {
  * + .subtitle-2 {
    margin-top: 5px;
  }
}

.about-wrap {
  margin: 0 auto;
  max-width: 1078px;
}

@media (max-width: 991.98px) {
  .br-none br {
    display: none;
  }
}

.text-wrap-about {
  max-width: 500px;
}

.text-wrap-about p {
  line-height: 146%;
}

.single-prod-wrap {
  padding-top: 55px;
  margin: 0 auto;
  max-width: 832px;
}

@media (max-width: 1199.98px) {
  .single-prod-wrap {
    padding-top: 30px;
  }
}

@media (max-width: 991.98px) {
  .single-prod-wrap {
    padding-top: 15px;
  }
}

.contacts-wrap {
  color: #0c1451;
  font-weight: 500;
  font-size: 22px;
  padding-right: 50px;
}

.contacts-wrap span {
  color: #494f7c;
  font-weight: 400;
}

.contacts-wrap p + .phone-link-second {
  margin-top: 20px;
}

.contacts-wrap p + span {
  margin-top: 10px;
}

.contacts-wrap * + p {
  margin-top: 15px;
}

.phone-wrap {
  display: flex;
}

/** @section Brand */
.brand {
  display: inline-block;
}

.brand .brand-logo-light {
  display: none;
}

.brand .brand-logo-dark {
  display: block;
}

/** @section Links */
a {
  transition: all 0.3s ease-in-out;
}

a,
a:focus,
a:active,
a:hover {
  text-decoration: none;
}

a,
a:focus,
a:active {
  color: #0c1451;
}

a:hover {
  color: #02040e;
}

a[href*="tel"],
a[href*="mailto"] {
  white-space: nowrap;
}

.link-hover {
  color: #02040e;
}

.link-press {
  color: #02040e;
}

.privacy-link {
  display: inline-block;
}

.privacy-link,
.privacy-link:focus,
.privacy-link:active {
  color: #0c1451;
}

.privacy-link:hover {
  color: #fe9b8e;
}

* + .privacy-link {
  margin-top: 25px;
}

/** @section Blocks */
.block-center,
.block-sm,
.block-lg {
  margin-left: auto;
  margin-right: auto;
}

.block-sm {
  max-width: 560px;
}

.block-lg {
  max-width: 768px;
}

.block-center {
  padding: 10px;
}

.block-center:hover .block-center-header {
  background-color: #0c1451;
}

.block-center-title {
  background-color: #ffffff;
}

@media (max-width: 1599.98px) {
  .block-center {
    padding: 20px;
  }
  .block-center:hover .block-center-header {
    background-color: #ffffff;
  }
  .block-center-header {
    background-color: #0c1451;
  }
}

/** @section Boxes */
.box-minimal {
  text-align: center;
}

.box-minimal .box-minimal-icon {
  margin: auto;
  padding: 23px;
  background: #fff1ef;
  border-radius: 50%;
  height: 90px;
  width: 90px;
}

.box-minimal .box-minimal-title {
  font-size: 32px;
  font-weight: 500;
}

.box-minimal .box-minimal-text {
  color: #494f7c;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  line-height: 32px;
  padding: 0px 20px 0px 20px;
  align-self: center;
  text-align: center;
}

.box-minimal-divider {
  width: 36px;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
  background: #0c1451;
}

.box-minimal-text {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

* + .box-minimal {
  margin-top: 30px;
}

* + .box-minimal-title {
  margin-top: 40px;
}

* + .box-minimal-divider {
  margin-top: 20px;
}

* + .box-minimal-text {
  margin-top: 30px;
}

.context-dark .box-minimal p,
.bg-gray-700 .box-minimal p,
.bg-primary .box-minimal p {
  color: #494f7c;
}

.box-counter {
  position: relative;
  text-align: center;
  color: #2c343b;
}

.box-counter-title {
  display: inline-block;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: -0.025em;
}

.box-counter-main {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 1.2;
}

.box-counter-main > * {
  display: inline;
  font: inherit;
}

.box-counter-main .small {
  font-size: 28px;
}

.box-counter-main .small_top {
  position: relative;
  top: 0.2em;
  vertical-align: top;
}

.box-counter-divider {
  font-size: 0;
  line-height: 0;
}

.box-counter-divider::after {
  content: "";
  display: inline-block;
  width: 80px;
  height: 2px;
  background: #0c1451;
}

* + .box-counter-title {
  margin-top: 10px;
}

* + .box-counter-main {
  margin-top: 20px;
}

* + .box-counter-divider {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .box-counter-title {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .box-counter-main {
    font-size: 60px;
  }
  .box-counter-main .small {
    font-size: 36px;
  }
  * + .box-counter-main {
    margin-top: 30px;
  }
  * + .box-counter-divider {
    margin-top: 15px;
  }
  * + .box-counter-title {
    margin-top: 18px;
  }
}

.bg-primary .box-counter {
  color: #ffffff;
}

.bg-primary .box-counter-divider::after {
  background: #ffffff;
}

.box-info-modern {
  position: relative;
  text-align: center;
  margin: 0 auto;
  max-width: 390px;
}

.box-info-modern-figure {
  display: block;
  padding: 10px;
}

.box-info-modern-figure img {
  width: 100%;
  transform: none;
  will-change: transform;
  transition: all 0.25s ease;
  max-height: 300px;
  border: 2px solid #fe9b8e;
}

.box-info-modern-title {
  color: #0c1451;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0.01em;
}

@media (max-width: 1199.98px) {
  .box-info-modern-title {
    font-size: 25px;
  }
}

.box-info-modern-title a {
  color: #0c1451;
}

.box-info-modern-title a:hover {
  color: #fe9b8e;
  transition: all 0.25s ease;
}

.box-info-modern-text {
  margin-left: auto;
  margin-right: auto;
  letter-spacing: normal;
  font-size: 22px;
  color: #494f7c;
}

.box-info-modern-text.small {
  font-size: 18px;
  color: #494f7c;
}

* + .box-info-modern {
  margin-top: 20px;
}

* + .box-info-modern-title {
  margin-top: 16px;
}

* + .box-info-modern-text {
  margin-top: 6px;
}

* + .box-info-modern-link {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .box-info-modern-figure {
    padding: 0;
  }
  * + .box-info-modern-title {
    margin-top: 55px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  * + .box-info-modern-title {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  * + .box-info-modern-text {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .desktop .box-info-modern-link:hover {
    color: #0c1451;
  }
}

.box-price-simple {
  padding: 0 60px;
  text-align: center;
  font-weight: 500;
}

@media (max-width: 1199.98px) {
  .box-price-simple {
    padding: 0 40px;
  }
}

.box-price-simple .box-price {
  color: #0c1451;
  font-size: 60px;
}

.box-price-simple .box-price-title {
  color: #0c1451;
  font-size: 32px;
  line-height: 116%;
}

.box-price-simple .box-price-descr {
  margin-top: 30px;
  font-size: 22px;
  font-weight: 400;
}

.box-price-simple * + .button {
  margin-top: 46px;
}

.voucher-box {
  box-shadow: 0px 14px 20px rgba(11, 20, 85, 0.1);
  border-radius: 8px;
  min-height: 500px;
  background: #ffffff;
  font-weight: 500;
  color: #0c1451;
  padding: 50px 42px;
  max-width: 390px;
  margin: 0 auto;
}

@media (max-width: 1199.98px) {
  .voucher-box {
    padding: 50px 35px;
  }
}

.voucher-box .voucher-name {
  color: #fe9b8e;
  font-size: 26px;
}

.voucher-box .voucher-title {
  font-size: 50px;
  line-height: 110%;
}

.voucher-box .voucher-text {
  font-size: 22px;
  line-height: 146%;
  color: #494f7c;
}

.voucher-box .voucher-name + .voucher-title {
  margin-top: 30px;
}

.voucher-box .voucher-title + .voucher-text {
  margin-top: 33px;
}

.voucher-box .voucher-text + .button {
  margin-top: 40px;
}

.voucher-box-dark {
  box-shadow: 0px 14px 20px rgba(11, 20, 85, 0.1);
  border-radius: 8px;
  min-height: 500px;
  background: #0c1451;
  font-weight: 500;
  color: #ffffff;
  padding: 50px 42px;
  margin: 0 auto;
  max-width: 390px;
}

@media (max-width: 1199.98px) {
  .voucher-box-dark {
    padding: 50px 35px;
  }
}

.voucher-box-dark .voucher-name {
  color: #fe9b8e;
  font-size: 26px;
}

.voucher-box-dark .voucher-title {
  font-size: 50px;
  line-height: 110%;
}

.voucher-box-dark .voucher-text {
  font-size: 22px;
  line-height: 146%;
}

.voucher-box-dark .voucher-name + .voucher-title {
  margin-top: 30px;
}

.voucher-box-dark .voucher-title + .voucher-text {
  margin-top: 33px;
}

.voucher-box-dark .voucher-text + .button {
  margin-top: 40px;
}

.video-wrap .video-cover {
  max-width: 668px;
}

@media (min-width: 992px) {
  .video-wrap .video-cover {
    margin-left: 54px;
    margin-top: 25px;
  }
}

.video-cover {
  position: relative;
  display: flex;
  padding-bottom: 53%;
  max-width: 1050px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

@media (max-width: 575.98px) {
  .video-cover {
    margin-top: 40px;
  }
}

.video-cover:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
}

.video-cover .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  font-size: 100px;
  line-height: 1;
  color: #ffffff;
  will-change: transform;
  transition: 0.2s transform;
}

.video-cover .icon:hover {
  transform: translate(-50%, -50%) scale(1.2);
}

.video-cover-16by9 {
  padding-bottom: 56.25%;
}

.video-cover-4by3 {
  padding-bottom: 75%;
}

.video-frame {
  position: relative;
  padding-bottom: 60%;
}

.video-frame .video-item {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

.testimonial-box {
  position: relative;
  color: #0c1451;
  max-width: 610px;
  padding: 61px 70px 65px 53px;
  border-radius: 8px;
  box-shadow: 0px 14px 20px rgba(11, 20, 85, 0.1);
  margin-left: 25px;
  margin-right: 25px;
}

@media (max-width: 1199.98px) {
  .testimonial-box {
    padding: 51px 60px 55px 43px;
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .testimonial-box {
    max-width: 80%;
  }
}

.testimonial-box .testimonial-rate {
  max-width: 146px;
  position: absolute;
  left: 63.5%;
  top: 70px;
}

@media (max-width: 1199.98px) {
  .testimonial-box .testimonial-rate {
    top: 60px;
  }
}

@media (max-width: 575.98px) {
  .testimonial-box .testimonial-rate {
    display: none;
  }
}

.testimonial-box .testimonial-title {
  font-size: 32px;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .testimonial-box .testimonial-title {
    font-size: 25px;
  }
}

.testimonial-box .testimonial-text {
  font-size: 22px;
  color: #494f7c;
}

@media (max-width: 767.98px) {
  .testimonial-box .testimonial-text {
    font-size: 19px;
  }
}

.testimonial-box .testimonial-name {
  font-size: 18px;
  color: #fe9b8e;
}

.testimonial-box * + .testimonial-text {
  margin-top: 34px;
}

.testimonial-box * + .testimonial-name {
  margin-top: 15px;
}

@media (min-width: 1600px) {
  .testimonial-box {
    max-width: 100%;
  }
}

* + .subtitle-box {
  margin-top: 36px;
}

.subtitle-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.subtitle-box-text {
  font-size: 19px;
  line-height: 44px;
  font-weight: 400;
  color: #494f7c;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .subtitle-box-text {
    font-size: 22px;
  }
}

.subtitle-box-media img {
  max-width: 90%;
}

@media (min-width: 768px) {
  .subtitle-box-media img {
    max-width: 100%;
  }
}

/** @section Groups */
html .group {
  margin-bottom: -20px;
  margin-left: -15px;
}

html .group:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html .group > * {
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 15px;
}

html .group-sm {
  margin-bottom: -10px;
  margin-left: -10px;
}

html .group-sm:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html .group-sm > * {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

html .group-md {
  margin-bottom: -20px;
  margin-left: -20px;
}

html .group-md:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html .group-md > * {
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 20px;
}

@media (min-width: 992px) {
  html .group-md {
    margin-bottom: -20px;
    margin-left: -20px;
  }
  html .group-md > * {
    margin-bottom: 20px;
    margin-left: 20px;
  }
}

html .group-xl {
  margin-bottom: -20px;
  margin-left: -30px;
}

html .group-xl:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html .group-xl > * {
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 30px;
}

@media (min-width: 992px) {
  html .group-xl {
    margin-bottom: -20px;
    margin-left: -45px;
  }
  html .group-xl > * {
    margin-bottom: 20px;
    margin-left: 45px;
  }
}

html .group-xxl {
  margin-bottom: -20px;
  margin-left: -30px;
}

html .group-xxl:empty {
  margin-bottom: 0;
  margin-left: 0;
}

html .group-xxl > * {
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 30px;
}

@media (min-width: 992px) {
  html .group-xxl {
    margin-bottom: -20px;
    margin-left: -45px;
  }
  html .group-xxl > * {
    margin-bottom: 20px;
    margin-left: 45px;
  }
}

html .group-middle {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}

html .group-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 103%;
}

html .group-custom > * {
  flex: 0 1 100%;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 576px) {
  html .group-custom {
    flex-wrap: nowrap;
  }
  html .group-custom > * {
    flex: 1 1 auto;
  }
}

* + .group-sm {
  margin-top: 30px;
}

* + .group-xl {
  margin-top: 20px;
}

/** @section Responsive units */
.unit {
  display: flex;
  flex: 0 1 100%;
  margin-bottom: -30px;
  margin-left: -20px;
}

.unit > * {
  margin-bottom: 30px;
  margin-left: 20px;
}

.unit:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.unit-body {
  flex: 0 1 auto;
}

.unit-left,
.unit-right {
  flex: 0 0 auto;
  max-width: 100%;
}

.unit-spacing-xs {
  margin-bottom: -15px;
  margin-left: -7px;
}

.unit-spacing-xs > * {
  margin-bottom: 15px;
  margin-left: 7px;
}

/** @section Lists */
.list > li + li {
  margin-top: 10px;
}

.list-xs > li + li {
  margin-top: 5px;
}

.list-sm > li + li {
  margin-top: 10px;
}

.list-md > li + li {
  margin-top: 18px;
}

.list-lg > li + li {
  margin-top: 25px;
}

.list-xl > li + li {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .list-xl > li + li {
    margin-top: 60px;
  }
}

.list-inline > li {
  display: inline-block;
}

html .list-inline-md {
  transform: translate3d(0, -8px, 0);
  margin-bottom: -8px;
  margin-left: -10px;
  margin-right: -10px;
}

html .list-inline-md > * {
  margin-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 992px) {
  html .list-inline-md {
    margin-left: -15px;
    margin-right: -15px;
  }
  html .list-inline-md > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.list-terms dt + dd {
  margin-top: 5px;
}

.list-terms dd + dt {
  margin-top: 25px;
}

* + .list-terms {
  margin-top: 25px;
}

.index-list {
  counter-reset: li;
}

.index-list > li .list-index-counter:before {
  content: counter(li, decimal-leading-zero);
  counter-increment: li;
}

.list-marked {
  text-align: left;
  color: #0c1451;
  font-weight: 500;
  font-size: 18px;
}

.list-marked > li {
  position: relative;
  padding-left: 30px;
}

.list-marked > li::before {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #fe9b8e;
}

.list-marked > li + li {
  margin-top: 13px;
}

* + .list-marked {
  margin-top: 70px;
}

p + .list-marked {
  margin-top: 10px;
}

.list-ordered {
  counter-reset: li;
  text-align: left;
}

.list-ordered > li {
  position: relative;
  padding-left: 25px;
}

.list-ordered > li:before {
  position: absolute;
  content: counter(li, decimal) ".";
  counter-increment: li;
  top: 0;
  left: 0;
  display: inline-block;
  width: 15px;
  color: #b7b7b7;
}

.list-ordered > li + li {
  margin-top: 10px;
}

* + .list-ordered {
  margin-top: 15px;
}

/** @section Images */
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-responsive {
  width: 100%;
}

* + .img-wrap {
  margin-top: 70px;
}

@media (max-width: 991.98px) {
  * + .img-wrap {
    margin-top: 50px;
  }
}

/** @section Icons */
.icon {
  display: inline-block;
  font-size: 24px;
  line-height: 1;
}

.icon:hover {
  color: #ffffff;
}

.icon::before {
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-transform: none;
}

.icon-default {
  color: #a5aad3;
}

.icon-circle {
  border-radius: 50%;
}

* + .icon {
  margin-left: 30px;
}

/** @section Tables custom */
.table-custom {
  width: 100%;
  max-width: 100%;
  text-align: left;
  background: #ffffff;
  border-collapse: collapse;
}

.table-custom th,
.table-custom td {
  color: #2c343b;
  background: #ffffff;
}

.table-custom th {
  padding: 35px 24px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: #d7d7d7;
}

@media (max-width: 991.98px) {
  .table-custom th {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.table-custom td {
  padding: 17px 24px;
}

.table-custom tbody tr:first-child td {
  border-top: 0;
}

.table-custom tr td {
  border-bottom: 1px solid #cccccc;
}

.table-custom tfoot td {
  font-weight: 700;
}

* + .table-custom-responsive {
  margin-top: 30px;
}

@media (min-width: 768px) {
  * + .table-custom-responsive {
    margin-top: 40px;
  }
}

.table-custom.table-custom-primary thead th {
  color: #ffffff;
  background: #0c1451;
  border: 0;
}

.table-custom.table-custom-primary tbody tr:hover td {
  background: #d7d7d7;
}

.table-custom.table-custom-bordered tr td:first-child {
  border-left: 0;
}

.table-custom.table-custom-bordered tr td:last-child {
  border-right: 0;
}

.table-custom.table-custom-bordered td {
  border: 1px solid #cccccc;
}

.table-custom.table-custom-bordered tbody > tr:first-of-type > td {
  border-top: 0;
}

.table-custom.table-custom-striped {
  border-bottom: 1px solid #cccccc;
}

.table-custom.table-custom-striped tbody tr:nth-of-type(odd) td {
  background: transparent;
}

.table-custom.table-custom-striped tbody tr:nth-of-type(even) td {
  background: #d7d7d7;
}

.table-custom.table-custom-striped tbody td {
  border: 0;
}

.table-custom.table-custom-striped tfoot td:not(:first-child) {
  border-left: 0;
}

@media (max-width: 991.98px) {
  .table-custom-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-custom-responsive.table-bordered {
    border: 0;
  }
}

/** @section Dividers */
hr {
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid #d7d7d7;
}

.divider {
  border-top: solid 3px #0c1451;
}

/** @section Buttons */
.button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 11px 35px;
  font-size: 18px;
  line-height: 1.25;
  border: 2px solid;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
  box-shadow: 6px 10px 16px rgba(11, 20, 85, 0.12);
  border-radius: 8px;
}

.button-block {
  display: block;
  width: 100%;
}

.button-default,
.button-default:focus {
  color: #cccccc;
  background-color: #2c343b;
  border-color: #2c343b;
}

.button-default:hover,
.button-default:active,
.button-default.active {
  color: #ffffff;
  background-color: #0c1451;
  border-color: #0c1451;
}

.button-default.button-ujarak::before {
  background: #0c1451;
}

.button-gray-100,
.button-gray-100:focus {
  color: #151515;
  background-color: #edeff4;
  border-color: #edeff4;
}

.button-gray-100:hover,
.button-gray-100:active,
.button-gray-100.active {
  color: #151515;
  background-color: #dde1ea;
  border-color: #dde1ea;
}

.button-gray-100.button-ujarak::before {
  background: #dde1ea;
}

.button-primary,
.button-primary:focus {
  color: #ffffff;
  background-color: #0c1451;
  border-color: #0c1451;
}

.button-primary:hover,
.button-primary:active,
.button-primary.active {
  color: #ffffff;
  background-color: #fe9b8e;
  border-color: #fe9b8e;
}

.button-primary.button-ujarak::before {
  background: #fe9b8e;
}

.button-secondary,
.button-secondary:focus {
  color: #ffffff;
  background-color: #fe9b8e;
  border-color: #fe9b8e;
}

.button-secondary:hover,
.button-secondary:active,
.button-secondary.active {
  color: #ffffff;
  background-color: #0c1451;
  border-color: #0c1451;
}

.button-secondary.button-ujarak::before {
  background: #0c1451;
}

.button-secondary-dark,
.button-secondary-dark:focus {
  color: #ffffff;
  background-color: #fe9b8e;
  border-color: #fe9b8e;
}

.button-secondary-dark:hover,
.button-secondary-dark:active,
.button-secondary-dark.active {
  color: #0c1451;
  background-color: #ffffff;
  border-color: #ffffff;
}

.button-secondary-dark.button-ujarak::before {
  background: #ffffff;
}

.button-white,
.button-white:focus {
  color: #0c1451;
  background-color: #ffffff;
  border-color: #ffffff;
}

.button-white:hover,
.button-white:active,
.button-white.active {
  color: #ffffff;
  background-color: #fe9b8e;
  border-color: #fe9b8e;
}

.button-white.button-ujarak::before {
  background: #fe9b8e;
}

.button-default-outline,
.button-default-outline:focus {
  color: #0c1451;
  background-color: transparent;
  border-color: #2c343b;
}

.button-default-outline:hover,
.button-default-outline:active,
.button-default-outline.active {
  color: #ffffff;
  background-color: #0c1451;
  border-color: #0c1451;
}

.button-default-outline.button-ujarak::before {
  background: #0c1451;
}

.button-ghost {
  border: 0;
  background-color: transparent;
}

.button-ghost:hover {
  color: #ffffff;
  background: #0c1451;
}

.button-facebook,
.button-facebook:focus {
  color: #ffffff;
  background-color: #4d70a8;
  border-color: #4d70a8;
}

.button-facebook:hover,
.button-facebook:active,
.button-facebook.active {
  color: #ffffff;
  background-color: #456497;
  border-color: #456497;
}

.button-facebook.button-ujarak::before {
  background: #456497;
}

.button-twitter,
.button-twitter:focus {
  color: #ffffff;
  background-color: #02bcf3;
  border-color: #02bcf3;
}

.button-twitter:hover,
.button-twitter:active,
.button-twitter.active {
  color: #ffffff;
  background-color: #02a8da;
  border-color: #02a8da;
}

.button-twitter.button-ujarak::before {
  background: #02a8da;
}

.button-google,
.button-google:focus {
  color: #ffffff;
  background-color: #e2411e;
  border-color: #e2411e;
}

.button-google:hover,
.button-google:active,
.button-google.active {
  color: #ffffff;
  background-color: #cc3a1a;
  border-color: #cc3a1a;
}

.button-google.button-ujarak::before {
  background: #cc3a1a;
}

.button-shadow {
  box-shadow: 0 9px 21px 0 rgba(204, 204, 204, 0.15);
}

.button-shadow:hover {
  box-shadow: 0 9px 10px 0 rgba(204, 204, 204, 0.15);
}

.button-shadow:focus,
.button-shadow:active {
  box-shadow: none;
}

.button-ujarak {
  position: relative;
  z-index: 0;
  transition: background 0.4s, border-color 0.4s, color 0.4s;
}

.button-ujarak::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0c1451;
  z-index: -1;
  opacity: 0;
  transform: scale3d(0.7, 1, 1);
  transition: transform 0.42s, opacity 0.42s;
  border-radius: inherit;
}

.button-ujarak,
.button-ujarak::before {
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button-ujarak:hover {
  transition: background 0.4s 0.4s, border-color 0.4s 0s, color 0.2s 0s;
}

.button-ujarak:hover::before {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

.button-xs {
  padding: 8px 17px;
}

.button-sm {
  padding: 15px 20px;
  font-size: 18px;
  line-height: 120%;
}

.button-md {
  padding: 20px 45px;
  font-size: 20px;
  line-height: 120%;
}

.button-lg {
  padding: 25px 60px;
  font-size: 22px;
  line-height: 120%;
}

.button-xl {
  padding: 34px 22px;
  font-size: 16px;
  line-height: 28px;
}

@media (min-width: 992px) {
  .button-xl {
    padding: 20px 80px;
  }
}

.button-circle {
  border-radius: 30px;
}

.button-round-1 {
  border-radius: 5px;
}

.button-round-2 {
  border-radius: 10px;
}

.button.button-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.button.button-icon .icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: inherit;
  font-size: 1.55em;
  line-height: 1em;
}

.button.button-icon-left .icon {
  padding-right: 11px;
}

.button.button-icon-right {
  flex-direction: row-reverse;
}

.button.button-icon-right .icon {
  padding-left: 11px;
}

.button.button-icon.button-link .icon {
  top: 5px;
  font-size: 1em;
}

.button.button-icon.button-xs .icon {
  top: 0.05em;
  font-size: 1.2em;
  padding-right: 8px;
}

.button.button-icon.button-xs .button-icon-right {
  padding-left: 8px;
}

.btn-primary {
  border-radius: 3px;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition: 0.33s;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
  color: #ffffff;
  background: #0c1451;
  border-color: #0c1451;
}

.btn-primary:hover {
  color: #ffffff;
  background: #000000;
  border-color: #000000;
}

/** @section Forms */
.rd-form {
  position: relative;
  text-align: left;
}

.rd-form .button {
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 50px;
}

* + .rd-form {
  margin-top: 20px;
}

input:-webkit-autofill ~ label,
input:-webkit-autofill ~ .form-validation {
  color: #000000 !important;
}

.form-wrap {
  position: relative;
}

.form-wrap.has-error .form-input {
  border-color: #f5543f;
}

.form-wrap.has-focus .form-input {
  border-color: #0c1451;
}

.form-wrap + * {
  margin-top: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 50px;
  padding: 6px 4px;
  font-size: 22px;
  font-weight: 400;
  line-height: 37px;
  color: #494f7c;
  background-image: none;
  -webkit-appearance: none;
  transition: 0.3s ease-in-out;
  border: none;
  border-bottom: 1px solid #e9eaef;
}

.form-input:focus {
  outline: 0;
  border-bottom: 2px solid #0c1451;
}

textarea.form-input {
  height: 135px;
  min-height: 50px;
  max-height: 230px;
  resize: vertical;
}

.recaptcha div {
  display: block;
  transform-origin: 0 0;
}

.recaptcha iframe {
  display: block;
}

.form-label,
.form-label-outside {
  margin-bottom: 0;
  color: #494f7c;
  font-weight: 400;
}

.form-label {
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 22px;
  font-weight: 400;
  line-height: 37px;
  pointer-events: none;
  text-align: left;
  z-index: 9;
  transition: 0.25s;
  will-change: transform;
  transform: translateY(-50%);
}

.form-label.focus {
  opacity: 0;
}

.form-label.auto-fill {
  color: #494f7c;
}

.form-label-outside {
  width: 100%;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .form-label-outside {
    position: static;
  }
  .form-label-outside,
  .form-label-outside.focus,
  .form-label-outside.auto-fill {
    transform: none;
  }
}

[data-x-mode="true"] .form-label {
  pointer-events: auto;
}

.form-validation {
  position: absolute;
  right: 8px;
  top: auto;
  z-index: 11;
  margin-top: 2px;
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  color: #f5543f;
  transition: 0.3s;
}

.form-validation-left .form-validation {
  right: auto;
  left: 8px;
}

#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  z-index: 2000;
  visibility: hidden;
  transform: translate3d(-500px, 0, 0);
  transition: 0.3s all ease;
}

#form-output-global.active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) {
  #form-output-global {
    left: 30px;
  }
}

.form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 2px;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.form-output.active {
  opacity: 1;
  visibility: visible;
}

.form-output.error {
  color: #f5543f;
}

.form-output.success {
  color: #98bf44;
}

.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
  opacity: 0;
}

.radio .radio-custom,
.radio .radio-custom-dummy,
.radio-inline .radio-custom,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom,
.checkbox-inline .checkbox-custom-dummy {
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  outline: none;
  cursor: pointer;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
  pointer-events: none;
  background: #ffffff;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after,
.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
  position: absolute;
  opacity: 0;
  transition: 0.22s;
}

.radio .radio-custom:focus,
.radio-inline .radio-custom:focus,
.checkbox .checkbox-custom:focus,
.checkbox-inline .checkbox-custom:focus {
  outline: none;
}

.radio input,
.radio-inline input,
.checkbox input,
.checkbox-inline input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
  opacity: 1;
}

.radio,
.radio-inline {
  padding-left: 28px;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy {
  top: 1px;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #b7b7b7;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after {
  content: "";
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  background: #2c343b;
  border-radius: inherit;
}

.checkbox,
.checkbox-inline {
  padding-left: 28px;
}

.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
  left: 0;
  width: 18px;
  height: 18px;
  margin: 0;
  border: 1px solid #b7b7b7;
}

.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
  content: "\f222";
  font-family: "Material Design Icons";
  position: absolute;
  top: -1px;
  left: -2px;
  font-size: 20px;
  line-height: 18px;
  color: #cccccc;
}

.toggle-custom {
  padding-left: 60px;
  -webkit-appearance: none;
}

.toggle-custom:checked ~ .checkbox-custom-dummy::after {
  background: #0c1451;
  transform: translate(20px, -50%);
}

.toggle-custom ~ .checkbox-custom-dummy {
  position: relative;
  display: inline-block;
  margin-top: -1px;
  width: 44px;
  height: 20px;
  background: #f5f5f5;
  cursor: pointer;
}

.toggle-custom ~ .checkbox-custom-dummy::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  background: #b7b7b7;
  transform: translate(4px, -50%);
  opacity: 1;
  transition: 0.22s;
}

.rd-form-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  text-align: center;
  transform: translate3d(0, -8px, 0);
  margin-bottom: -8px;
  margin-left: -4px;
  margin-right: -4px;
}

.rd-form-inline > * {
  margin-top: 8px;
  padding-left: 4px;
  padding-right: 4px;
}

.rd-form-inline > * {
  margin-top: 0;
}

.rd-form-inline .form-wrap {
  flex-grow: 1;
  min-width: 185px;
}

.rd-form-inline .form-wrap-select {
  text-align: left;
}

.rd-form-inline .form-button {
  flex-shrink: 0;
  max-width: calc(100% - 10px);
  min-height: 50px;
}

.rd-form-inline .form-button .button {
  min-height: inherit;
}

@media (min-width: 576px) {
  .rd-form-inline .button {
    display: block;
  }
}

.rd-form-inline.rd-form-inline-centered {
  justify-content: center;
}

.form-sm .form-input,
.form-sm .button {
  padding-top: 9px;
  padding-bottom: 9px;
  min-height: 40px;
}

.form-sm .form-validation {
  top: -16px;
}

.form-sm .form-label {
  top: 20px;
}

.form-sm * + .button {
  margin-top: 10px;
}

.rd-form-3 .form-input {
  padding: 9px 4px;
  background: transparent;
}

.rd-form-3.black-border .form-input {
  border-color: #0c1451;
}

@media (max-width: 447px) {
  .form-button-2 {
    margin-top: 20px;
    width: 100%;
  }
}

.form-lg .form-input,
.form-lg .form-label,
.form-lg .select2-container .select2-choice {
  font-size: 16px;
}

.form-lg .form-input,
.form-lg .select2-container .select2-choice {
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.form-lg .form-input,
.form-lg .select2-container .select2-choice {
  min-height: 58px;
}

.form-lg .form-button {
  min-height: 60px;
}

.form-lg .form-label {
  top: 30px;
}

.rd-form-2 .form-input {
  padding: 12px 4px;
}

.rd-form-2
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding-top: 0;
  padding-bottom: 24px;
}

/** @section Posts */
/** @section Quotes */
.quote-primary-mark {
  position: relative;
  top: 6px;
  display: block;
  width: 37px;
  height: 27px;
  fill: #0c1451;
  flex-shrink: 0;
}

.quote-primary-body {
  position: relative;
  padding: 25px 0;
  border-top: 1px solid #d7d7d7;
}

.quote-primary-body::before,
.quote-primary-body::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 13px;
  border-style: solid;
  border-color: #d7d7d7;
}

.quote-primary-body::before {
  left: 0;
  width: 78px;
  border-width: 1px 1px 0 0;
}

.quote-primary-body::after {
  right: 0;
  width: calc(100% - 78px - 12px);
  border-width: 1px 0 0 1px;
  transform-origin: 0 0;
  transform: skew(-45deg);
}

.quote-primary-text {
  padding-top: 20px;
}

.quote-primary-cite {
  font-weight: 700;
}

.quote-primary-footer {
  padding-left: 5px;
}

* + .quote-primary {
  margin-top: 30px;
}

* + .quote-primary-footer {
  margin-top: 8px;
}

@media (min-width: 576px) {
  .quote-primary-body {
    display: flex;
    padding: 32px 20px 40px 30px;
  }
  .quote-primary-text {
    padding-top: 0;
    padding-left: 20px;
  }
  .quote-primary-footer {
    padding-left: 30px;
  }
}

@media (min-width: 768px) {
  .quote-primary-body {
    padding-right: 40px;
  }
}

/** @section Thumbnails */
.figure-light figcaption {
  padding-top: 10px;
  color: #b7b7b7;
}

/** @section Breadcrumbs */
.breadcrumbs-custom {
  position: relative;
  padding: 35px 0 40px;
  text-align: center;
  background-color: #feebe8;
  background-position: center;
}

.breadcrumbs-custom-path {
  margin-left: -15px;
  margin-right: -15px;
}

.breadcrumbs-custom-path > * {
  padding-left: 15px;
  padding-right: 15px;
}

.breadcrumbs-custom-path a {
  display: inline;
  vertical-align: middle;
}

.breadcrumbs-custom-path a,
.breadcrumbs-custom-path a:active,
.breadcrumbs-custom-path a:focus {
  color: #0c1451;
}

.breadcrumbs-custom-path li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.7;
}

.breadcrumbs-custom-path li::after {
  content: "";
  font-family: "FontAwesome";
  position: absolute;
  top: 52%;
  right: -5px;
  display: inline-block;
  font-size: inherit;
  font-style: normal;
  color: #0c1451;
  transform: translate3d(0, -50%, 0);
}

.breadcrumbs-custom-path li:last-child:after {
  display: none;
}

.breadcrumbs-custom-path a:hover,
.breadcrumbs-custom-path li.active {
  color: #fe9b8e;
}

.breadcrumbs-custom-path li.active {
  top: 2px;
}

* + .breadcrumbs-custom-path {
  margin-top: 14px;
}

@media (max-width: 991.98px) {
  .breadcrumbs-custom-title {
    font-size: 26px;
  }
}

@media (min-width: 576px) {
  .breadcrumbs-custom {
    margin-left: -30px;
    margin-right: -30px;
  }
  .breadcrumbs-custom > * {
    padding-left: 30px;
    padding-right: 30px;
  }
  * + .breadcrumbs-custom-path {
    margin-top: 18px;
  }
}

@media (min-width: 768px) {
  .breadcrumbs-custom {
    padding: 70px 0 80px;
  }
  .breadcrumbs-custom-path li {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .breadcrumbs-custom {
    padding: 80px 0 100px;
  }
}

@media (min-width: 1600px) {
  .breadcrumbs-custom {
    padding: 80px 0 100px;
  }
}

/** @section Pagination custom */
.pagination {
  font-size: 0;
  line-height: 0;
  margin-bottom: -10px;
  margin-left: -10px;
}

.pagination:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.pagination > * {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

.page-item {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-weight: 700;
}

.page-link {
  display: inline-block;
  min-width: 48px;
  padding: 10px 10px;
  border: 2px solid #d7d7d7;
  font-size: 14px;
  line-height: 1.71429;
  background-color: #ffffff;
  color: #9b9b9b;
  transition: all 0.3s ease-in-out;
}

.page-link:hover,
.page-link:focus,
.page-link:active {
  color: #ffffff;
  background-color: #0c1451;
  border-color: #0c1451;
}

.page-item.active > .page-link,
.page-item.active > .page-link:hover,
.page-item.active > .page-link:focus,
.page-item.active > .page-link:active {
  color: #ffffff;
  background-color: #0c1451;
  border-color: #0c1451;
}

.page-item.disabled > .page-link,
.page-item.disabled > .page-link:hover,
.page-item.disabled > .page-link:focus,
.page-item.disabled > .page-link:active {
  color: #b7b7b7;
  background-color: #d7d7d7;
  border-color: #d7d7d7;
}

.page-item-control .icon::before {
  font-family: "FontAwesome";
  font-size: 11px;
  line-height: 24px;
}

.page-item-control:first-child .icon::before {
  content: "\f053";
  margin-left: -1px;
}

.page-item-control:last-child .icon::before {
  content: "\f054";
  margin-right: -1px;
}

/** @section Snackbars */
.snackbars {
  padding: 9px 16px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  text-align: left;
  background-color: #151515;
  border-radius: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  max-width: 280px;
  font-size: 14px;
}

.snackbars .icon-xxs {
  font-size: 18px;
}

.snackbars p span:last-child {
  padding-left: 14px;
}

.snackbars-left {
  display: inline-block;
  margin-bottom: 0;
}

.snackbars-right {
  display: inline-block;
  float: right;
  text-transform: uppercase;
}

.snackbars-right:hover {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .snackbars {
    max-width: 540px;
    padding: 12px 15px;
    font-size: 15px;
  }
}

.snackbars a,
.snackbars a:focus,
.snackbars a:active {
  color: #ffffff;
}

.snackbars a:hover {
  color: #fe9b8e;
}

/** @section Footers */
.footer-classic {
  padding: 100px 0;
  background: #0b1455;
  color: #ffffff;
}

.footer-classic h3,
.footer-classic h4,
.footer-classic p {
  color: #ffffff;
}

.footer-classic p {
  font-size: 22px;
  font-weight: 500;
  line-height: 146%;
}

@media (max-width: 991.98px) {
  .footer-classic p {
    font-size: 18px;
  }
}

.footer-classic .phone-sub {
  font-size: 26px;
  color: #fe9b8e;
  font-weight: 700;
}

.footer-classic .phone-link {
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
}

.footer-classic .phone-link:hover {
  color: #fe9b8e;
}

.footer-classic p + ul {
  margin-top: 30px;
}

.footer-classic .rights {
  font-size: 14px;
  color: #a5aad3;
}

.footer-classic .rights a:hover {
  color: #fe9b8e;
}

.footer-classic .list-wrapper {
  margin-top: 40px;
  border-top: solid 3px #3c4377;
  border-bottom: solid 1px #3c4377;
  padding: 50px 0;
  font-size: 18px;
}

.footer-classic .list-wrapper a {
  color: #a5aad3;
}

.footer-classic .list-wrapper a:hover {
  color: #fe9b8e;
}

.footer-classic .date {
  font-size: 14px;
  color: #a5aad3;
}

img + p {
  margin-top: 24px;
}

* + .date {
  margin-top: 14px;
}

* + .list-wrapper {
  margin-top: 20px;
}

/** @group Plugins */
/** @section Animate */
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 1;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

html:not(.lt-ie10) .not-animated {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes slideInDown {
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideOutDown {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

/** @section Preloader */
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  transition: 0.3s all ease;
}

.preloader.loaded {
  opacity: 0;
  visibility: hidden;
}

.preloader-body {
  text-align: center;
}

.preloader-body p {
  position: relative;
  right: -8px;
}

.cssload-container {
  width: 100%;
  height: 36px;
  text-align: center;
}

.cssload-speeding-wheel {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  border: 3px solid #0c1451;
  border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  animation: cssload-spin 0.88s infinite linear;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
  }
}

/** @section ToTop */
.ui-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 100;
  width: 50px;
  height: 50px;
  font-size: 40px;
  line-height: 46px;
  color: #fe9b8e;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  transition: 0.45s all ease-in-out;
  transform: translate3d(0, 100px, 0);
  box-shadow: 0px 5px 10px rgba(11, 20, 85, 0.1);
}

.ui-to-top:hover {
  color: #0c1451;
  background: #fe9b8e;
  text-decoration: none;
}

.ui-to-top:focus {
  color: #0c1451;
}

.ui-to-top.active {
  transform: translate3d(0, 0, 0);
}

html.mobile .ui-to-top,
html.tablet .ui-to-top {
  display: none !important;
}

@media (min-width: 576px) {
  .ui-to-top {
    right: 40px;
    bottom: 40px;
  }
}

/** @section RD Navbar */
@keyframes rd-navbar-slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rd-navbar-slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.rd-navbar-wrap,
.rd-navbar,
.rd-menu,
.rd-navbar-nav,
.rd-navbar-panel,
.rd-navbar-static .rd-menu,
.rd-navbar-fixed .rd-navbar-nav-wrap,
.rd-navbar-fixed .rd-navbar-submenu {
  transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}

.rd-navbar--no-transition,
.rd-navbar--no-transition * {
  transition: none !important;
}

.rd-navbar,
.rd-navbar.rd-navbar--is-clone {
  display: none;
}

.rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
  display: none;
}

.rd-navbar {
  display: none;
  background: #ffffff;
}

.rd-navbar-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #cccccc;
  background-color: transparent;
  border: none;
  display: none;
}

.rd-navbar-toggle span {
  position: relative;
  display: block;
  margin: auto;
  transition: 0.3s all ease;
}

.rd-navbar-toggle span:after,
.rd-navbar-toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -8px;
  transition: 0.3s all ease;
}

.rd-navbar-toggle span:after {
  top: 8px;
}

.rd-navbar-toggle span:after,
.rd-navbar-toggle span:before,
.rd-navbar-toggle span {
  width: 24px;
  height: 4px;
  background-color: #0c1451;
  backface-visibility: hidden;
  border-radius: 0;
}

.rd-navbar-toggle span {
  transform: rotate(180deg);
}

.rd-navbar-toggle span:before,
.rd-navbar-toggle span:after {
  transform-origin: 1.71429px center;
}

.rd-navbar-toggle.active span {
  transform: rotate(360deg);
}

.rd-navbar-toggle.active span:before,
.rd-navbar-toggle.active span:after {
  top: 0;
  width: 15px;
}

.rd-navbar-toggle.active span:before {
  -webkit-transform: rotate3d(0, 0, 1, -40deg);
  transform: rotate3d(0, 0, 1, -40deg);
}

.rd-navbar-toggle.active span:after {
  -webkit-transform: rotate3d(0, 0, 1, 40deg);
  transform: rotate3d(0, 0, 1, 40deg);
}

.rd-navbar-collapse-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #0c1451;
  display: none;
}

.rd-navbar-collapse-toggle span {
  top: 50%;
  margin-top: -3px;
}

.rd-navbar-collapse-toggle span,
.rd-navbar-collapse-toggle span:before,
.rd-navbar-collapse-toggle span:after {
  position: absolute;
  width: 6px;
  height: 6px;
  line-height: 6px;
  text-align: center;
  background: #0c1451;
  left: 50%;
  margin-left: -3px;
  border-radius: 50%;
  transition: 0.3s all ease;
}

.rd-navbar-collapse-toggle span:before,
.rd-navbar-collapse-toggle span:after {
  content: "";
}

.rd-navbar-collapse-toggle span:before {
  bottom: 100%;
  margin-bottom: 3px;
}

.rd-navbar-collapse-toggle span:after {
  top: 100%;
  margin-top: 3px;
}

.rd-navbar-collapse-toggle.active span {
  transform: scale(0.7);
}

.rd-navbar-collapse-toggle.active span:before {
  transform: translateY(18px);
}

.rd-navbar-collapse-toggle.active span:after {
  transform: translateY(-18px);
}

.rd-navbar-brand a {
  display: block;
}

.rd-navbar-search {
  position: relative;
  display: inline-flex;
}

.rd-navbar-search .form-input {
  padding-right: 50px;
}

.rd-navbar-search .rd-search-form-submit {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #cccccc;
  transition: 0.33s;
}

.rd-navbar-search .rd-search-form-submit:hover {
  color: #0c1451;
}

.rd-navbar-search-toggle {
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;
}

.rd-navbar-search-toggle::-moz-focus-inner {
  border: none;
  padding: 0;
}

.rd-navbar-dropdown {
  display: none;
}

/*
* @subsection  RD Navbar Static
*/
.rd-navbar-static {
  display: block;
}

.rd-navbar-static .rd-nav-item {
  display: inline-block;
}

.rd-navbar-static .rd-nav-item.focus .rd-nav-link,
.rd-navbar-static .rd-nav-item.opened .rd-nav-link {
  color: #fe9b8e;
  background: transparent;
}

.rd-navbar-static .rd-nav-item.focus > .rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item.opened > .rd-navbar-submenu-toggle {
  color: #fe9b8e;
}

.rd-navbar-static .rd-nav-item.active .rd-nav-link {
  color: #fe9b8e;
  background: transparent;
}

.rd-navbar-static .rd-nav-item.active > .rd-navbar-submenu-toggle {
  color: #fe9b8e;
}

.rd-navbar-static .rd-nav-item.focus > .rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item.opened > .rd-navbar-submenu-toggle::before,
.rd-navbar-static
  .rd-nav-item
  .rd-nav-link:hover
  + .rd-navbar-submenu-toggle::before {
  transform: rotate(180deg);
}

.rd-navbar-static .rd-nav-item > .rd-navbar-submenu {
  margin-top: 20px;
}

.rd-navbar-static .rd-nav-item + .rd-nav-item {
  margin-left: 38px;
}

@media (max-width: 1199.98px) {
  .rd-navbar-static .rd-nav-item + .rd-nav-item {
    margin-left: 10px;
  }
}

.rd-navbar-static .rd-nav-link {
  position: relative;
  display: inline-block;
  color: #0c1451;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  transition: 0.25s;
}

.rd-navbar-static .rd-:hover {
  color: #fe9b8e;
}

.rd-navbar-static .rd-menu {
  z-index: 15;
  position: absolute;
  display: block;
  margin-top: 22px;
  visibility: hidden;
  opacity: 0;
  text-align: left;
  box-shadow: 0px 5px 10px rgba(11, 20, 85, 0.1);
  transform: translate3d(0, 30px, 0);
}

.rd-navbar-static .rd-navbar-dropdown {
  position: absolute;
  left: 0;
  width: 270px;
  padding: 20px;
  background: #ffffff;
  z-index: 5;
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown {
  top: -1px;
  left: 100%;
  z-index: 2;
  margin-top: -15px;
  margin-left: 15px;
  transform: translate3d(30px, 0, 0);
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown.rd-navbar-open-left {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: 15px;
  transform: translate3d(-30px, 0, 0);
}

.rd-navbar-static .rd-dropdown-item.focus .rd-dropdown-link,
.rd-navbar-static .rd-dropdown-item.opened .rd-dropdown-link {
  color: #fe9b8e;
}

.rd-navbar-static .rd-dropdown-link {
  color: #0c1451;
}

.rd-navbar-static .rd-dropdown-link:hover {
  color: #fe9b8e;
}

.rd-navbar-static .rd-navbar-dropdown li > a,
.rd-navbar-static .rd-megamenu-list li > a {
  position: relative;
  left: -3px;
  display: flex;
  padding: 1px 14px 1px 0;
  text-align: left;
}

.rd-navbar-static .rd-navbar-dropdown li > a,
.rd-navbar-static .rd-navbar-dropdown li > a:focus,
.rd-navbar-static .rd-navbar-dropdown li > a:active,
.rd-navbar-static .rd-megamenu-list li > a,
.rd-navbar-static .rd-megamenu-list li > a:focus,
.rd-navbar-static .rd-megamenu-list li > a:active {
  color: #0c1451;
}

.rd-navbar-static .rd-navbar-dropdown li > a:hover,
.rd-navbar-static .rd-megamenu-list li > a:hover {
  color: #fe9b8e;
}

.rd-navbar-static .rd-navbar-dropdown li > a,
.rd-navbar-static .rd-navbar-dropdown li > a::before,
.rd-navbar-static .rd-megamenu-list li > a,
.rd-navbar-static .rd-megamenu-list li > a::before {
  transition: 0.15s ease-in-out;
}

.rd-navbar-static .rd-navbar-dropdown li > a::before,
.rd-navbar-static .rd-megamenu-list li > a::before {
  position: relative;
  top: -1px;
  left: -6px;
  display: inline-block;
  content: "\f105";
  font-family: "FontAwesome";
  font-size: inherit;
  line-height: inherit;
  color: #fe9b8e;
  opacity: 0;
  visibility: hidden;
}

.rd-navbar-static .rd-navbar-dropdown li.focus > a,
.rd-navbar-static .rd-navbar-dropdown li.opened > a,
.rd-navbar-static .rd-navbar-dropdown li > a:hover,
.rd-navbar-static .rd-megamenu-list li.focus > a,
.rd-navbar-static .rd-megamenu-list li.opened > a,
.rd-navbar-static .rd-megamenu-list li > a:hover {
  left: 0;
  padding-left: 14px;
  padding-right: 0;
}

.rd-navbar-static .rd-navbar-dropdown li.focus > a::before,
.rd-navbar-static .rd-navbar-dropdown li.opened > a::before,
.rd-navbar-static .rd-navbar-dropdown li > a:hover::before,
.rd-navbar-static .rd-megamenu-list li.focus > a::before,
.rd-navbar-static .rd-megamenu-list li.opened > a::before,
.rd-navbar-static .rd-megamenu-list li > a:hover::before {
  left: -7px;
  opacity: 1;
  visibility: visible;
}

.rd-navbar-static .rd-navbar-megamenu {
  display: flex;
  left: 0;
  width: 100%;
  max-width: 1200px;
  padding: 30px 40px;
  background: #ffffff;
}

.rd-navbar-static .rd-megamenu-item {
  flex-basis: 50%;
  flex-grow: 1;
}

.rd-navbar-static .rd-megamenu-item + .rd-megamenu-item {
  padding-left: 20px;
}

.rd-navbar-static .rd-megamenu-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #0c1451;
}

.rd-navbar-static * + .rd-megamenu-title {
  margin-top: 30px;
}

.rd-navbar-static .rd-megamenu-list-item + .rd-megamenu-list-item {
  margin-top: 5px;
}

.rd-navbar-static .rd-megamenu-list-link {
  color: #000000;
  background: transparent;
}

.rd-navbar-static .rd-megamenu-list-link:hover {
  color: #0c1451;
  background: transparent;
}

.rd-navbar-static * + .rd-megamenu-list {
  margin-top: 10px;
}

.rd-navbar-static .rd-navbar-submenu.focus > .rd-menu,
.rd-navbar-static .rd-navbar-submenu.opened > .rd-menu {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.rd-navbar-static .rd-navbar-search.active .rd-search {
  visibility: visible;
  opacity: 1;
}

.rd-navbar-static .rd-search {
  position: absolute;
  top: 100%;
  right: 0;
  width: 270px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  margin-top: 20px;
  z-index: 2;
}

.rd-navbar-static * + .rd-navbar-search {
  margin-left: 38px;
}

.rd-navbar-static .rd-navbar-search-toggle {
  display: inline-flex;
  color: #0c1451;
}

.rd-navbar-static .rd-navbar-search-toggle span {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.rd-navbar-static .rd-navbar-search-toggle span,
.rd-navbar-static .rd-navbar-search-toggle span::before,
.rd-navbar-static .rd-navbar-search-toggle span::after {
  transition: 0.3s all ease-in-out;
}

.rd-navbar-static .rd-navbar-search-toggle span::before,
.rd-navbar-static .rd-navbar-search-toggle span::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rd-navbar-static .rd-navbar-search-toggle span::before {
  content: "";
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
  font-family: "Material Design Icons";
}

.rd-navbar-static .rd-navbar-search-toggle span::after {
  content: "";
  transform: rotate(-90deg) scale(0.4);
  opacity: 0;
  visibility: hidden;
  font-family: "Material Design Icons";
}

.rd-navbar-static .rd-navbar-search-toggle.active span::before {
  opacity: 0;
  visibility: hidden;
  transform: rotate(90deg) scale(0.4);
}

.rd-navbar-static .rd-navbar-search-toggle.active span::after {
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
}

.rd-navbar-static .rd-navbar-search-toggle:hover {
  color: #fe9b8e;
}

.rd-navbar-static.rd-navbar--is-clone {
  display: block;
  transform: translate3d(0, -100%, 0);
}

.rd-navbar-static.rd-navbar--is-clone.rd-navbar--is-stuck {
  transform: translate3d(0, 0, 0);
}

.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-static.rd-navbar--is-clone {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1080;
  background: #ffffff;
}

.rd-navbar-static .rd-navbar--has-dropdown {
  position: relative;
}

/*
*
* RD Navbar Fixed
*/
.rd-navbar-fixed {
  display: block;
}

.rd-navbar-fixed .rd-navbar-toggle {
  display: inline-block;
}

.rd-navbar-fixed .rd-navbar-brand {
  position: relative;
  margin-left: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 22px;
  line-height: 46px;
}

.rd-navbar-fixed .rd-navbar-brand img {
  max-width: 160px;
  max-height: 58px;
  width: auto;
  height: auto;
}

.rd-navbar-fixed .rd-navbar-panel {
  box-shadow: 0px 5px 10px rgba(12, 20, 81, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1080;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 4px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
  position: fixed;
  left: 0;
  top: -56px;
  bottom: -56px;
  z-index: 1079;
  width: 270px;
  padding: 112px 0 81px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 14px;
  line-height: 34px;
  transform: translateX(-110%);
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar {
  width: 4px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-thumb {
  background: #fec8c1;
  border: none;
  border-radius: 0;
  opacity: 0.2;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-track {
  background: #feebe8;
  border: none;
  border-radius: 0;
}

.rd-navbar-fixed .rd-navbar-nav-wrap.active {
  transform: translateX(0);
}

.rd-navbar-fixed .rd-navbar-nav {
  display: block;
  margin: 15px 0;
  height: auto;
  text-align: left;
}

.rd-navbar-fixed .rd-nav-item {
  text-align: left;
}

.rd-navbar-fixed .rd-nav-item + .rd-nav-item {
  margin-top: 4px;
}

@media (max-width: 991.98px) {
  .rd-navbar-fixed .rd-nav-item .btn-wrap {
    display: none;
  }
}

.rd-navbar-fixed .rd-nav-link {
  display: block;
  padding: 9px 56px 9px 18px;
}

.rd-navbar-fixed li.opened > .rd-navbar-dropdown {
  padding: 4px 0 0;
}

.rd-navbar-fixed li.opened > .rd-navbar-megamenu {
  padding-top: 15px;
  padding-bottom: 15px;
}

.rd-navbar-fixed li.opened > .rd-menu {
  opacity: 1;
  height: auto;
}

.rd-navbar-fixed li.opened > .rd-navbar-submenu-toggle::after {
  transform: rotate(180deg);
}

.rd-navbar-fixed .rd-menu {
  display: none;
  transition: opacity 0.3s, height 0.4s ease;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.rd-navbar-fixed .rd-navbar-submenu {
  position: relative;
}

.rd-navbar-fixed
  .rd-navbar-submenu
  .rd-navbar-dropdown
  .rd-navbar-submenu-toggle:after {
  height: 34px;
  line-height: 34px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown > li > a {
  padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li > a,
.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li > a {
  padding-left: 46px;
}

.rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-megamenu {
  display: block;
}

.rd-navbar-fixed .rd-megamenu-list > li > a,
.rd-navbar-fixed .rd-navbar-dropdown > li > a {
  display: block;
  padding: 9px 56px 9px 16px;
  font-size: 14px;
  line-height: 1.5;
}

.rd-navbar-fixed .rd-megamenu-list > li + li,
.rd-navbar-fixed .rd-navbar-dropdown > li + li {
  margin-top: 3px;
}

.rd-navbar-fixed .rd-megamenu-list > li > a {
  padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
  position: relative;
  display: block;
  padding: 0 18px 7px;
  font-size: 14px;
  line-height: 1.5;
  color: #fe9b8e;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title::after {
  content: "";
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-bottom: 1px solid;
}

.rd-navbar-fixed .rd-navbar-megamenu * + .rd-megamenu-list {
  margin-top: 11px;
}

.rd-navbar-fixed .rd-navbar-megamenu * + .rd-megamenu-title {
  margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-megamenu > li + li {
  margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
  cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle::after {
  content: "\f107";
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 52px;
  font: 400 14px "FontAwesome";
  line-height: 52px;
  text-align: center;
  transition: 0.4s all ease;
  z-index: 2;
  cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-collapse-toggle {
  display: block;
  top: 4px;
  z-index: 1081;
}

.rd-navbar-fixed .rd-navbar-collapse {
  position: fixed;
  right: 0;
  top: 56px;
  z-index: 1079;
  transform: translate3d(0, -10px, 0);
  padding: 15px;
  width: auto;
  border-radius: 0;
  text-align: left;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.rd-navbar-fixed .rd-navbar-collapse.active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.rd-navbar-fixed .rd-navbar-collapse .list-inline > li {
  display: block;
}

.rd-navbar-fixed .rd-navbar-collapse .list-inline > li + li {
  margin-top: 10px;
}

.rd-navbar-fixed .rd-navbar-main-element {
  position: absolute;
  float: left;
}

.rd-navbar-fixed .rd-navbar-search .rd-search {
  position: fixed;
  right: 0;
  left: 0;
  top: 56px;
  z-index: 1079;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: translate3d(0, -10px, 0);
}

.rd-navbar-fixed .rd-navbar-search .rd-search-results-live {
  display: none;
}

.rd-navbar-fixed .rd-navbar-search .form-input {
  padding-right: 50px;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

.rd-navbar-fixed .rd-navbar-search.active .rd-search {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
  display: inline-flex;
}

.rd-navbar-fixed .rd-navbar-search-toggle span {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.rd-navbar-fixed .rd-navbar-search-toggle span,
.rd-navbar-fixed .rd-navbar-search-toggle span::before,
.rd-navbar-fixed .rd-navbar-search-toggle span::after {
  transition: 0.3s all ease-in-out;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before,
.rd-navbar-fixed .rd-navbar-search-toggle span::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before {
  content: "";
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
  font-family: "Material Design Icons";
}

.rd-navbar-fixed .rd-navbar-search-toggle span::after {
  content: "";
  transform: rotate(-90deg) scale(0.4);
  opacity: 0;
  visibility: hidden;
  font-family: "Material Design Icons";
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::before {
  opacity: 0;
  visibility: hidden;
  transform: rotate(90deg) scale(0.4);
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::after {
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
}

.rd-navbar-fixed [class*="rd-navbar-fixed-element"] {
  position: fixed;
  top: 4px;
  z-index: 1082;
}

.rd-navbar-fixed .rd-navbar-fixed-element-1 {
  right: 0;
}

.rd-navbar-fixed .rd-navbar-fixed-element-2 {
  right: 46px;
}

.rd-navbar-fixed.rd-navbar--is-clone {
  display: none;
}

.rd-navbar-fixed .rd-navbar-fixed--visible {
  display: block;
}

.rd-navbar-fixed .rd-navbar-fixed--hidden {
  display: none;
}

.rd-navbar-fixed .rd-navbar-panel {
  color: #0c1451;
  box-shadow: 0px 5px 10px rgba(11, 20, 85, 0.1);
  background: #ffffff;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
  color: #ffffff;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(11, 20, 85, 0.1);
}

.rd-navbar-fixed .rd-nav-item {
  color: #0c1451;
}

.rd-navbar-fixed .rd-nav-item:hover .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.focus .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.active .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.opened .rd-nav-link {
  color: #ffffff;
  background: #0c1451;
}

.rd-navbar-fixed .rd-nav-item:hover > .rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.focus > .rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.active > .rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.opened > .rd-navbar-submenu-toggle {
  color: #ffffff;
}

.rd-navbar-fixed .rd-nav-link {
  color: #0c1451;
}

.rd-navbar-fixed .rd-megamenu-list > li > a,
.rd-navbar-fixed .rd-navbar-dropdown > li > a {
  color: #0c1451;
}

.rd-navbar-fixed .rd-megamenu-list > li:hover > a,
.rd-navbar-fixed .rd-megamenu-list > li.focus > a,
.rd-navbar-fixed .rd-megamenu-list > li.active > a,
.rd-navbar-fixed .rd-megamenu-list > li.opened > a,
.rd-navbar-fixed .rd-navbar-dropdown > li:hover > a,
.rd-navbar-fixed .rd-navbar-dropdown > li.focus > a,
.rd-navbar-fixed .rd-navbar-dropdown > li.active > a,
.rd-navbar-fixed .rd-navbar-dropdown > li.opened > a {
  color: #ffffff;
  background: #0c1451;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
  color: #cccccc;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title::after {
  border-bottom: 1px solid #0c1451;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:focus,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:active {
  color: #ffffff;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:hover {
  color: #0c1451;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
  color: #0c1451;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
  color: #cccccc;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit:hover {
  color: #0c1451;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
  color: #0c1451;
}

.rd-navbar-fixed .rd-navbar-search-toggle:hover {
  color: #0c1451;
}

.rd-navbar-fixed .rd-navbar-collapse {
  background-color: #ffffff;
  box-shadow: 0 0 22px -4px rgba(0, 0, 0, 0.17);
}

.rd-navbar-fixed .rd-navbar-collapse,
.rd-navbar-fixed .rd-navbar-collapse a {
  color: #0c1451;
}

html.rd-navbar-fixed-linked .page {
  padding-top: 56px;
}

/*
*
* RD Navbar Sidebar
*/
.rd-navbar-sidebar {
  display: block;
  background: #ffffff;
}

.rd-navbar-sidebar .rd-navbar-main-outer {
  padding-left: 15px;
  padding-right: 15px;
}

.rd-navbar-sidebar .rd-navbar-main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 60px 22px 0;
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.rd-navbar-sidebar .rd-navbar-main-element {
  display: flex;
  align-items: center;
}

.rd-navbar-sidebar .rd-navbar-main-element > * + * {
  margin-left: 20px;
}

.rd-navbar-sidebar .rd-navbar-toggle {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  z-index: 1102;
}

.rd-navbar-sidebar .rd-navbar-panel {
  min-width: 200px;
  text-align: center;
}

.rd-navbar-sidebar .rd-navbar-brand img {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
}

.rd-navbar-sidebar .rd-navbar-nav-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1101;
  width: 450px;
  padding: 94px 0 0 0;
  background: #ffffff;
  transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateX(100%);
  box-shadow: 0 0 13px 0 rgba(204, 204, 204, 0.16);
}

.rd-navbar-sidebar .rd-navbar-nav-wrap.active {
  transition-delay: 0.1s;
  transform: translateX(0);
}

.rd-navbar-sidebar .rd-navbar-nav {
  height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #d7d7d7;
}

.rd-navbar-sidebar .rd-nav-item {
  position: relative;
  display: block;
  padding: 19px 30px 19px 40px;
}

.rd-navbar-sidebar .rd-nav-item.active .rd-nav-link {
  color: #0c1451;
}

.rd-navbar-sidebar .rd-nav-item .rd-nav-link,
.rd-navbar-sidebar .rd-nav-item > .rd-navbar-submenu-toggle {
  font-size: 18px;
}

.rd-navbar-sidebar .rd-nav-item + .rd-nav-item {
  border-top: 1px solid #d7d7d7;
}

.rd-navbar-sidebar .rd-nav-item:last-child {
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-nav-link {
  max-width: calc(100% - 30px);
  word-break: break-all;
  font-size: 18px;
  line-height: 1.4;
  text-transform: none;
  color: #cccccc;
}

.rd-navbar-sidebar .rd-nav-link:hover {
  color: #0c1451;
}

.rd-navbar-sidebar
  .rd-navbar-submenu
  .opened
  > .rd-navbar-submenu-toggle::after {
  transform: rotate(180deg);
}

.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-megamenu {
  display: none;
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
  transition: opacity 0.2s;
}

.rd-navbar-sidebar .rd-navbar-submenu.opened > .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu.opened > .rd-navbar-megamenu {
  display: block;
  opacity: 1;
  visibility: visible;
}

.rd-navbar-sidebar
  .rd-navbar-submenu
  > .rd-navbar-submenu
  > .rd-navbar-dropdown,
.rd-navbar-sidebar
  .rd-navbar-submenu
  > .rd-navbar-submenu
  > .rd-navbar-megamenu {
  transform: translateY(30px);
}

.rd-navbar-sidebar
  .rd-navbar-submenu
  > .rd-navbar-submenu.opened
  > .rd-navbar-dropdown,
.rd-navbar-sidebar
  .rd-navbar-submenu
  > .rd-navbar-submenu.opened
  > .rd-navbar-megamenu {
  transform: translateY(0);
}

.rd-navbar-sidebar
  .rd-navbar-submenu
  > .rd-navbar-submenu
  .rd-navbar-submenu
  > .rd-navbar-dropdown {
  transform: translateX(-20px);
}

.rd-navbar-sidebar
  .rd-navbar-submenu
  > .rd-navbar-submenu
  .rd-navbar-submenu.opened
  > .rd-navbar-dropdown {
  transform: translateX(0);
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-submenu-toggle,
.rd-navbar-sidebar
  .rd-nav-item
  .rd-navbar--has-dropdown
  > .rd-navbar-submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-left: 10px;
  margin-bottom: inherit;
  display: inline-block;
  width: 30px;
  margin-left: 5px;
  text-align: center;
  cursor: pointer;
  color: #0c1451;
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-submenu-toggle:hover,
.rd-navbar-sidebar
  .rd-nav-item
  .rd-navbar--has-dropdown
  > .rd-navbar-submenu-toggle:hover {
  color: #0c1451;
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-submenu-toggle::after,
.rd-navbar-sidebar
  .rd-nav-item
  .rd-navbar--has-dropdown
  > .rd-navbar-submenu-toggle::after {
  content: "\f107";
  position: relative;
  display: inline-block;
  font-family: "FontAwesome";
  font-size: inherit;
  line-height: inherit;
  text-align: center;
  vertical-align: middle;
  transition: 0.4s all ease;
  z-index: 2;
  will-change: transform;
}

.rd-navbar-sidebar
  .rd-nav-item
  .rd-navbar--has-dropdown
  > .rd-navbar-submenu-toggle {
  font-size: 14px;
  color: #cccccc;
  margin-left: 7px;
}

.rd-navbar-sidebar .rd-menu {
  margin-top: 31px;
}

.rd-navbar-sidebar .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-megamenu-list {
  font-size: 14px;
}

.rd-navbar-sidebar .rd-navbar-dropdown li > a,
.rd-navbar-sidebar .rd-megamenu-list li > a {
  position: relative;
  left: -3px;
  display: flex;
  padding: 1px 14px 1px 0;
  text-align: left;
}

.rd-navbar-sidebar .rd-navbar-dropdown li > a,
.rd-navbar-sidebar .rd-navbar-dropdown li > a:focus,
.rd-navbar-sidebar .rd-navbar-dropdown li > a:active,
.rd-navbar-sidebar .rd-megamenu-list li > a,
.rd-navbar-sidebar .rd-megamenu-list li > a:focus,
.rd-navbar-sidebar .rd-megamenu-list li > a:active {
  color: #0c1451;
}

.rd-navbar-sidebar .rd-navbar-dropdown li > a:hover,
.rd-navbar-sidebar .rd-megamenu-list li > a:hover {
  color: #fe9b8e;
}

.rd-navbar-sidebar .rd-navbar-dropdown li > a,
.rd-navbar-sidebar .rd-navbar-dropdown li > a::before,
.rd-navbar-sidebar .rd-megamenu-list li > a,
.rd-navbar-sidebar .rd-megamenu-list li > a::before {
  transition: 0.15s ease-in-out;
}

.rd-navbar-sidebar .rd-navbar-dropdown li > a::before,
.rd-navbar-sidebar .rd-megamenu-list li > a::before {
  position: relative;
  top: -1px;
  left: -6px;
  display: inline-block;
  content: "\f105";
  font-family: "FontAwesome";
  font-size: inherit;
  line-height: inherit;
  color: #fe9b8e;
  opacity: 0;
  visibility: hidden;
}

.rd-navbar-sidebar .rd-navbar-dropdown li.focus > a,
.rd-navbar-sidebar .rd-navbar-dropdown li.opened > a,
.rd-navbar-sidebar .rd-navbar-dropdown li > a:hover,
.rd-navbar-sidebar .rd-megamenu-list li.focus > a,
.rd-navbar-sidebar .rd-megamenu-list li.opened > a,
.rd-navbar-sidebar .rd-megamenu-list li > a:hover {
  left: 0;
  padding-left: 14px;
  padding-right: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown li.focus > a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li.opened > a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li > a:hover::before,
.rd-navbar-sidebar .rd-megamenu-list li.focus > a::before,
.rd-navbar-sidebar .rd-megamenu-list li.opened > a::before,
.rd-navbar-sidebar .rd-megamenu-list li > a:hover::before {
  left: -7px;
  opacity: 1;
  visibility: visible;
}

.rd-navbar-sidebar .rd-navbar-megamenu {
  max-width: 450px;
  margin-bottom: -30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu > * {
  margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu > li {
  display: inline-block;
  vertical-align: top;
  width: 45%;
}

.rd-navbar-sidebar .rd-megamenu-title {
  display: none;
}

.rd-navbar-sidebar .rd-megamenu-list {
  margin-top: 0;
}

.rd-navbar-sidebar .rd-megamenu-list > li + li {
  margin-top: 10px;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown > a {
  padding-right: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown > a::before {
  display: none;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown > a:hover {
  padding-left: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.focus > a {
  padding-left: 0;
}

.rd-navbar-sidebar
  .rd-navbar-dropdown
  .rd-navbar--has-dropdown.opened
  > .rd-navbar-submenu-toggle {
  color: #0c1451;
}

.rd-navbar-sidebar
  .rd-navbar-dropdown
  .rd-navbar--has-dropdown.opened
  > .rd-navbar-submenu-toggle::after {
  top: 1px;
}

.rd-navbar-sidebar .rd-navbar-dropdown > li + li {
  margin-top: 10px;
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-dropdown {
  margin-top: 25px;
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-dropdown .rd-navbar-dropdown {
  margin-top: 10px;
  padding-left: 15px;
}

.rd-navbar-sidebar
  .rd-nav-item
  > .rd-navbar-dropdown
  .rd-navbar-dropdown
  > li
  > a {
  font-size: 12px;
}

@media (min-width: 1200px) {
  .rd-navbar-sidebar .rd-navbar-megamenu {
    margin-bottom: -30px;
  }
  .rd-navbar-sidebar .rd-navbar-megamenu > * {
    margin-bottom: 30px;
  }
  .rd-navbar-sidebar .rd-navbar-dropdown > li + li,
  .rd-navbar-sidebar .rd-megamenu-list > li + li {
    margin-top: 18px;
  }
}

@media (min-width: 1200px) {
  .rd-navbar-sidebar .rd-navbar-nav-wrap {
    width: auto;
    left: calc(50% + 600px - 80px);
  }
}

.rd-navbar-sidebar.rd-navbar--is-clone {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transform: translateY(-101%);
}

.rd-navbar-sidebar.rd-navbar--is-clone.rd-navbar--is-stuck {
  transform: translateY(0);
}

.rd-navbar-sidebar.rd-navbar--is-stuck {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
}

.rd-navbar-sidebar.rd-navbar--is-stuck .rd-navbar-main {
  padding-top: 11px;
  padding-bottom: 11px;
}

/**
*
* RD Navbar Classic
* =====================================================
*/
.rd-navbar-classic.rd-navbar-static {
  border-bottom: 1px solid transparent;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-aside-outer,
.rd-navbar-classic.rd-navbar-static .rd-navbar-main-outer {
  padding-left: 15px;
  padding-right: 15px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-aside,
.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-aside {
  padding: 10px 0;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand img {
  height: auto;
  max-height: 200px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main-element {
  display: flex;
  align-items: center;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone {
  box-shadow: 0px 5px 10px rgba(11, 20, 85, 0.1);
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-aside-outer,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-aside-outer {
  display: none;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-main,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-main {
  padding: 9px 0;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck
  .rd-navbar-nav-item
  > .rd-navbar-submenu,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone
  .rd-navbar-nav-item
  > .rd-navbar-submenu {
  margin-top: 17px;
}

/*
*
* RD Navbar Fullwidth
*/
.rd-navbar-fullwidth {
  display: block;
}

.rd-navbar-fullwidth .rd-navbar-nav > li + li {
  margin-left: 20px;
}

.rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-main,
.rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-main {
  padding: 10px 0;
}

/** @section Swiper */
.swiper-container {
  display: flex;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  width: 100%;
  height: auto;
  min-height: inherit;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container {
  flex-shrink: 0;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  touch-action: pan-x;
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

.swiper-slide > .vide__body,
.swiper-slide > .parallax_cnt {
  height: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  font-size: 26px;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease-in;
  will-change: transform;
  z-index: 10;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #0c1451;
}

@media (min-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: block;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev {
  left: 30px;
}

.swiper-button-prev:before {
  font-family: "FontAwesome";
  content: "\f060";
}

.swiper-button-next {
  right: 30px;
}

.swiper-button-next:before {
  font-family: "FontAwesome";
  content: "\f061";
}

.swiper-slider.swiper-container-rtl .swiper-button-prev::before {
  content: "\f061";
}

.swiper-slider.swiper-container-rtl .swiper-button-next::before {
  content: "\f060";
}

.swiper-pagination {
  position: absolute;
  display: block;
  text-align: center;
  transition: 0.3s;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.6);
  transition: 0.2s;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination .swiper-pagination-bullet:hover {
  background: white;
}

.swiper-pagination.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-black .swiper-pagination-bullet {
  background: rgba(0, 0, 0, 0.6);
}

.swiper-pagination-black
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: black;
}

.swiper-container-vertical > .swiper-pagination {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal > .swiper-pagination {
  bottom: 20px;
  left: 0;
  width: 100%;
}

.swiper-container-horizontal > .swiper-pagination .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.swiper-slide-caption {
  width: 100%;
  white-space: normal;
}

.swiper-slider-1 {
  min-height: 40.10417vw;
}

/** @section Google Map */
.google-map-markers {
  display: none;
}

.google-map-container {
  width: 100%;
}

.google-map {
  height: 250px;
  width: 100%;
  height: 250px;
  height: 570px;
}

/** @section Search Results */
.rd-search-results-live {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  max-height: 600px;
  overflow-y: auto;
  margin: -3px 0 0;
  text-align: left;
  z-index: 998;
}

.rd-search-results-live #search-results {
  position: relative;
  padding: 16px 0 0;
  color: #494f7c;
  background: #f5f5f5;
  opacity: 0;
  visibility: hidden;
  transform-origin: 50% 0;
  transform: scale(1, 0.9);
  transition: 0.3s all ease;
}

.rd-search-results-live #search-results::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
  border: 1px solid #d7d7d7;
  border-top: 0;
}

.rd-search-results-live #search-results.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1);
}

.rd-search-results-live .search-quick-result {
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  color: #0c1451;
}

.rd-search-results-live .search-list {
  margin-top: 11px;
}

.rd-search-results-live .search-list li:only-child {
  padding: 0 15px 15px;
}

.rd-search-results-live .search-link {
  color: #cccccc;
}

.rd-search-results-live .search-link:hover {
  color: #0c1451;
}

.rd-search-results-live .search-error {
  font-size: 14px;
  line-height: 1.6;
}

.rd-search-results-live .search-title {
  position: relative;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #0c1451;
}

.rd-search-results-live .search-title a:hover {
  color: #0c1451;
}

.rd-search-results-live .search-list-item-all {
  margin-top: 18px;
  width: 100%;
}

.rd-search-results-live .search-submit {
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: block;
  padding: 8px;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  text-align: center;
  color: #ffffff;
  background: #0c1451;
}

.rd-search-results-live .search-submit:hover {
  color: #ffffff;
  background: #cccccc;
}

.rd-search-results-live .match {
  display: none;
}

.not-empty ~ .rd-search-results-live {
  visibility: visible;
  opacity: 1;
}

.rd-search-results-live p {
  font-size: 11px;
}

.rd-search-results-live p * {
  margin: 0;
}

.rd-search-results-live .search-list-item {
  padding: 0 15px;
}

.rd-search-results-live * + p {
  margin-top: 5px;
}

.rd-search-results-live .search-list-item + .search-list-item {
  margin-top: 17px;
}

.rd-search-results .search-list {
  counter-reset: li;
  text-align: left;
  padding-left: 0;
  font-size: 18px;
  list-style-type: none;
  overflow: hidden;
}

.rd-search-results .search-list li div {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

.rd-search-results .search-list li:only-child::before {
  display: none;
}

.rd-search-results .search-list-item {
  position: relative;
  padding-left: 40px;
  font-size: 18px;
  color: #9b9b9b;
}

.rd-search-results .search-list-item::before {
  content: counter(li, decimal-leading-zero) ".";
  counter-increment: li;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: inherit;
  font-weight: 500;
  color: #000000;
}

.rd-search-results .search-list-item + .search-list-item {
  margin-top: 40px;
}

.rd-search-results .search-title {
  font-size: 16px;
  color: #000000;
}

.rd-search-results .search {
  color: #ffffff;
  padding: 0 0.25em;
  background: #0c1451;
}

.rd-search-results .match {
  padding: 5px;
  font-size: 12px;
  line-height: 1.7;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}

.rd-search-results .match em {
  margin: 0;
  font-style: normal;
}

.rd-search-results p * {
  margin: 0;
}

.rd-search-results * + .match {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .rd-search-results .search-title {
    font-size: 18px;
  }
  .rd-search-results .search-list-item::before {
    top: -1px;
    font-size: 16px;
  }
  .rd-search-results * + p {
    margin-top: 12px;
  }
}

@media (min-width: 992px) {
  .rd-search-results .search-list-item {
    padding-left: 40px;
  }
}

* + .rd-search-results {
  margin-top: 40px;
}

@media (min-width: 768px) {
  * + .rd-search-results {
    margin-top: 55px;
  }
}

.rd-search-classic {
  position: relative;
}

.rd-search-classic .form-input {
  padding-right: 50px;
}

.rd-search-classic .form-input,
.rd-search-classic .form-label {
  letter-spacing: 0;
}

.rd-search-classic .rd-search-submit {
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  padding-top: 3px;
  padding-right: 2px;
  margin: 0;
  text-align: center;
  color: #cccccc;
  font-size: 0;
  line-height: 0;
}

.rd-search-classic .rd-search-submit::-moz-focus-inner {
  border: none;
  padding: 0;
}

.rd-search-classic .rd-search-submit:before {
  position: relative;
  top: -1px;
  content: "\e014";
  font: 400 20px "fl-chapps";
  line-height: 1;
  transition: 0.33s all ease;
}

.rd-search-classic .rd-search-submit:hover {
  color: #0c1451;
}

.rd-search.rd-search-inline {
  position: relative;
}

.rd-search.rd-search-inline .form-input {
  padding-right: 50px;
}

.rd-search.rd-search-inline .button-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  right: 0;
  margin: 0;
  font-size: 21px;
  color: #cccccc;
  transition: 0.33s;
}

.rd-search.rd-search-inline .button-link::before {
  display: block;
  margin: auto;
}

.rd-search.rd-search-inline .button-link:hover {
  color: #0c1451;
}

.rd-search.rd-search-inline.form-sm .form-input {
  padding-right: 40px;
}

.rd-search.rd-search-inline.form-sm .button-link {
  width: 40px;
  font-size: 18px;
}

.rd-search.rd-search-inline.form-lg .form-input {
  padding-right: 60px;
}

.rd-search.rd-search-inline.form-lg .button-link {
  width: 60px;
}

/** @section Isotope */
.isotope {
  z-index: 0;
  display: block;
  transition: 0.3s ease;
}

.isotope-sizer {
  height: 0;
  margin-top: -1px;
  margin-bottom: 0;
}

.isotope-item {
  display: block;
  will-change: transform;
  backface-visibility: hidden;
  transition: 0.1s opacity ease-in;
}

.isotope-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

* + .isotope {
  margin-top: 28px;
}

/** @section Nav custom */
.tabs-custom {
  text-align: left;
}

.tabs-custom .nav-tabs {
  font-size: 0;
  line-height: 0;
  word-spacing: 0;
  border: 0;
}

.tabs-custom .nav-tabs:before,
.tabs-custom .nav-tabs:after {
  display: none;
}

.tabs-custom .nav-item {
  float: none;
  border: 0;
  cursor: pointer;
  transition: 0.33s all ease;
}

.tabs-custom .nav-link {
  margin: 0;
  border-radius: 0;
  border: 0;
}

.tabs-custom .nav-link.active {
  cursor: default;
}

.tab-content > .tab-pane {
  display: block;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.tab-content > .active {
  visibility: visible;
  height: auto;
  overflow: visible;
}

* + .tabs-horizontal.tabs-corporate {
  margin-top: 40px;
}

* + .tabs-vertical.tabs-corporate {
  margin-top: 40px;
}

@media (min-width: 768px) {
  * + .tabs-vertical.tabs-corporate {
    margin-top: 60px;
  }
}

@media (min-width: 1200px) {
  * + .tabs-vertical.tabs-corporate {
    margin-top: 80px;
  }
}

.tabs-line .nav-tabs {
  position: relative;
}

.tabs-line .nav-link {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  vertical-align: middle;
}

.tabs-line * + .tab-content {
  margin-top: 20px;
}

@media (max-width: 991.98px) {
  .tabs-line .nav-tabs {
    max-width: 100%;
    border: 1px solid #e5e7e9;
  }
  .tabs-line .nav-item {
    margin: -1px;
  }
  .tabs-line .nav-link {
    padding: 7px 10px;
    color: #494f7c;
    background: transparent;
    border-bottom: 1px solid #e5e7e9;
  }
  .tabs-line .nav-link:first-child {
    border-top: 1px solid #e5e7e9;
  }
  .tabs-line .nav-link:hover,
  .tabs-line .nav-link.active {
    color: #ffffff;
    background: #0c1451;
    border-color: #0c1451;
  }


}

.tabs-corporate .nav-tabs {
  position: relative;
  margin-bottom: -10px;
  margin-left: -10px;
}

.tabs-corporate .nav-tabs:empty {
  margin-bottom: 0;
  margin-left: 0;
}

.tabs-corporate .nav-tabs > * {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

.tabs-corporate .nav-link {
  padding: 10px 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  color: #494f7c;
  background: transparent;
  border: 1px solid #e5e7e9;
  text-align: center;
  vertical-align: middle;
}

.tabs-corporate .nav-link:hover,
.tabs-corporate .nav-link.active {
  color: #ffffff;
  background: #0c1451;
  border-color: #0c1451;
}


.tabs-corporate .tab-content {
  padding: 30px 0 0;
}

@media (min-width: 768px) {
  .tabs-horizontal.tabs-corporate .nav-tabs {
    position: relative;
    width: 100%;
    display: block;
    text-align: center;
    border: 0;
    will-change: transform;
  }
  .tabs-horizontal.tabs-corporate .nav-item {
    display: inline-block;
    will-change: transform;
  }
  .tabs-horizontal.tabs-corporate .nav-link {
    display: block;
    position: relative;
    z-index: 1;
    min-width: 130px;
    letter-spacing: 0.1em;
    padding: 13px 20px;
    border: 2px solid #e5e7e9;
  }
  .tabs-horizontal.tabs-corporate .nav-link,
  .tabs-horizontal.tabs-corporate .nav-link::before {
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  .tabs-horizontal.tabs-corporate .nav-link::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -1;
    opacity: 0;
    transform: scale3d(0.7, 1, 1);
    transition: transform 0.4s, opacity 0.4s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    background: #0c1451;
  }
  .tabs-horizontal.tabs-corporate .nav-link.active,
  .tabs-horizontal.tabs-corporate .nav-link:hover {
    color: #ffffff;
    border-color: #0c1451;
    background-color: transparent;
  }


  .tabs-horizontal.tabs-corporate .nav-link.active::before,
  .tabs-horizontal.tabs-corporate .nav-link:hover::before {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }


  .tabs-horizontal.tabs-line .nav-tabs {
    border: 0;
    border-bottom: 4px solid #d7d7d7;
  }
  .tabs-horizontal.tabs-line .nav-item {
    display: inline-block;
  }
  .tabs-horizontal.tabs-line .nav-item:not(:last-child) {
    margin-right: 52px;
  }
  .tabs-horizontal.tabs-line .nav-link {
    position: relative;
    padding: 0 0 15px 0;
    background-color: transparent;
    color: #9b9b9b;
    border: 0;
  }
  .tabs-horizontal.tabs-line .nav-link::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 4px;
    width: 0;
    background: #0c1451;
    transition: 0.22s ease;
  }
  .tabs-horizontal.tabs-line .nav-link.active,
  .tabs-horizontal.tabs-line .nav-link:hover {
    color: #cccccc;
  }

  .tabs-horizontal.tabs-line .nav-link.active::after {
    width: 100%;
  }
  .tabs-horizontal.tabs-line * + .tab-content {
    margin-top: 30px;
  }
  .tabs-vertical {
    display: flex;
    align-items: flex-start;
  }
  .tabs-vertical .nav-tabs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-shrink: 0;
    max-width: 50%;
  }
  .tabs-vertical .nav-item {
    border: 0;
    width: 100%;
    text-align: left;
  }
  .tabs-vertical .nav-link.active,
  .tabs-vertical .nav-link:hover {
    box-shadow: 0 9px 21px 0 rgba(30, 30, 30, 0.13);
  }


  .tabs-vertical .tab-content {
    flex-grow: 1;
  }
  .tabs-vertical.tabs-corporate .nav-tabs {
    width: auto;
    min-width: 260px;
    border: 0;
  }
  .tabs-vertical.tabs-corporate .nav-item {
    margin: 0;
  }
  .tabs-vertical.tabs-corporate .nav-link {
    position: relative;
    padding: 17px 30px;
    border: 0;
    overflow: hidden;
    text-align: left;
  }
  .tabs-vertical.tabs-corporate .nav-item + .nav-item {
    margin-top: 2px;
  }
  .tabs-vertical.tabs-corporate .tab-content {
    padding: 0 0 0 30px;
  }
}

@media (min-width: 992px) {
  .tabs-horizontal.tabs-corporate .tab-content {
    padding: 30px 30px 0;
  }
  .tabs-vertical.tabs-corporate .tab-content {
    padding: 0 0 0 45px;
  }
}

/** @section Panel custom */
.card-group-custom {
  margin-bottom: 0;
}

.card-group-custom .card-header + .collapse > .card-body,
.card-group-custom .card-header + .collapse > .list-group {
  border-top: 0;
}

.card-group-custom .card + .card {
  margin-top: 38px;
}

.card-group-custom.card-group-corporate .card + .card {
  margin-top: 30px;
}

.card-custom {
  display: block;
  margin: 0;
  background: inherit;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  text-align: left;
}

.card-custom a {
  display: block;
}

.card-custom .card-header {
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-custom .card-body {
  padding: 0;
  border-bottom: solid 1px #e9eaef;
}

.card-custom .card-body p {
  font-size: 18px;
  color: #494f7c;
}

.card-custom .card-title {
  margin-bottom: 0;
}

* + .card-group-custom {
  margin-top: 22px;
}

@media (min-width: 768px) {
  * + .card-group-custom {
    margin-top: 22px;
  }
}

.card-corporate {
  text-align: left;
}

.card-corporate .card-title a,
.card-corporate .card-body {
  background: #ffffff;
}

.card-corporate .card-title a {
  position: relative;
  z-index: 1;
  padding: 0px 82px 38px 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.25;
  letter-spacing: 0;
  color: #0c1451;
  transition: 1.3s all ease;
  border-radius: 6px 6px 0 0;
}

@media (max-width: 991.98px) {
  .card-corporate .card-title a {
    padding: 10px 10px 10px 0;
  }
}

.card-corporate .card-title a .card-arrow:after {
  opacity: 0;
  visibility: hidden;
}

.card-corporate .card-title a.collapsed {
  border-bottom: 1px solid #e9eaef;
}

.card-corporate .card-title a.collapsed .card-arrow {
  border-radius: 0 6px 6px 0;
}

.card-corporate .card-title a.collapsed .card-arrow:after {
  opacity: 1;
  visibility: visible;
}

.card-corporate .card-arrow {
  position: absolute;
  top: 0;
  bottom: -1px;
  right: -1px;
  z-index: 2;
  width: 70px;
  background: #0c1451;
  border-radius: 0 6px 0 0;
  transition: 0.33s all ease;
}

.card-corporate .card-arrow::before,
.card-corporate .card-arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  z-index: 4;
  transform: translateY(-50%);
  background: #ffffff;
}

.card-corporate .card-arrow::before {
  width: 14px;
  height: 2px;
  right: 28px;
}

.card-corporate .card-arrow::after {
  width: 2px;
  height: 14px;
  right: 34px;
}

.card-corporate .collapse {
  position: relative;
  z-index: 1;
  color: #494f7c;
  border-radius: 0 0 6px 6px;
}

.card-corporate .card-body {
  padding: 20px 44px 26px 0;
}

@media (min-width: 992px) {
  .card-corporate .card-body {
    margin-top: -20px;
  }
}

/** @section Tooltop Custom */
.tooltip {
  font-size: 18px;
  line-height: 1.2;
}

.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #0c1451;
}

.tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #0c1451;
}

/** @section Counter */
/** @section Progress Bars */
.progress-linear {
  position: relative;
  text-align: left;
}

.progress-linear-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate3d(0, -10px, 0);
  margin-bottom: -10px;
  margin-left: -5px;
  margin-right: -5px;
}

.progress-linear-header > * {
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.progress-linear-body {
  height: 10px;
  background: #d7d7d7;
}

.progress-linear-bar {
  width: 0;
  height: inherit;
  background: #0c1451;
  transition: 1s all ease-in-out;
}

.progress-linear-counter {
  font-weight: 700;
  color: #cccccc;
}

.progress-linear-counter::after {
  content: "%";
}

* + .progress-linear-body {
  margin-top: 8px;
}

.progress-linear + .progress-linear {
  margin-top: 15px;
}

@media (min-width: 992px) {
  .progress-linear + .progress-linear {
    margin-top: 25px;
  }
}

/** @section Progress Circle */
.progress-circle {
  display: inline-block;
  text-align: center;
  line-height: 1.2;
}

.progress-circle-block {
  position: relative;
}

.progress-circle-bg {
  fill: transparent;
  stroke: #edeff4;
  stroke-width: 10px;
}

.progress-circle-fg {
  fill: transparent;
  stroke: #0c1451;
  stroke-width: 10px;
}

.progress-circle-counter {
  position: absolute;
  top: 50%;
  left: 51%;
  font-size: 36px;
  line-height: 36px;
  transform: translate(-50%, -50%);
  color: #9b9b9b;
}

.progress-circle-counter::after {
  content: "%";
}

.progress-circle-title {
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

* + .progress-bar-circle-title {
  margin-top: 12px;
}

/** @section Countdown */
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
  line-height: 1.2;
  text-align: center;
}

.countdown-block {
  position: relative;
  flex: 0 1 auto;
  padding: 0 5px;
}

.ios .countdown-block {
  max-width: 25%;
}

.ios .countdown-title {
  font-size: 10px;
}

@media (min-width: 992px) {
  .ios .countdown-title {
    font-size: 13px;
  }
}

.countdown-block-seconds {
  display: none;
}

.countdown-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.countdown-circle {
  fill: transparent;
}

html:not(.ie-11):not(.ie-10) .countdown-circle {
  width: 100%;
  height: auto;
}

.countdown-circle-bg {
  stroke: white;
  stroke-width: 5px;
}

.countdown-circle-fg {
  stroke: #0c1451;
  stroke-width: 10px;
}

.countdown-counter {
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  color: #0c1451;
}

.countdown-title {
  font-size: 13px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #0c1451;
}

* + .countdown {
  margin-top: 35px;
}

.countdown + * {
  margin-top: 35px;
}

@media (min-width: 576px) {
  .countdown-block-seconds {
    display: block;
  }
}

@media (min-width: 768px) {
  .countdown {
    margin-left: -15px;
    margin-right: -15px;
  }
  .countdown-block {
    padding: 0 15px;
  }
  .countdown-counter {
    font-size: 34px;
  }
  .countdown-circle-bg {
    stroke-width: 3px;
  }
  .countdown-circle-fg {
    stroke-width: 5px;
  }
}

@media (min-width: 992px) {
  .countdown-counter {
    font-size: 48px;
  }
}

.context-dark .countdown-counter,
.bg-gray-700 .countdown-counter,
.bg-primary .countdown-counter {
  color: #ffffff;
}

/** @section Owl Carousel */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
  margin-top: 120px;
}

@media (max-width: 1199.98px) {
  .owl-carousel {
    margin-top: 50px;
  }
}

@media (max-width: 767.98px) {
  .owl-carousel {
    margin-top: 10px;
  }
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
  padding-bottom: 30px;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav,
.owl-carousel .owl-dots {
  -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-prev,
.owl-carousel .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  user-select: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-nav {
  pointer-events: none;
}

.owl-carousel .owl-prev,
.owl-carousel .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 40px;
  line-height: 1;
  cursor: pointer;
  pointer-events: auto;
  background: #0c1451;
  color: #8488a7;
}

.owl-carousel .owl-prev::before,
.owl-carousel .owl-prev::after,
.owl-carousel .owl-next::before,
.owl-carousel .owl-next::after {
  display: block;
  font-family: "FontAwesome";
}

.owl-carousel .owl-prev:hover,
.owl-carousel .owl-next:hover {
  text-decoration: none;
  background: #8488a7;
  color: #0c1451;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-carousel .owl-prev {
  left: 10px;
}

.owl-carousel .owl-prev::before {
  content: "\f104";
}

.owl-carousel .owl-next {
  right: 10px;
}

.owl-carousel .owl-next::before {
  content: "\f105";
}

.owl-carousel .owl-dots {
  text-align: center;
}

.owl-carousel .owl-dot {
  display: inline-block;
  zoom: 1;
  margin: 0 15px;
}

.owl-carousel .owl-dot span {
  width: 13px;
  height: 13px;
  background: #8488a7;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 10px;
}

.owl-carousel .owl-dot.active span,
.owl-carousel .owl-dot:hover span {
  background: #0c1451;
}

.owl-theme-1 .owl-stage-outer {
  padding-top: 4px;
}

/** @section Lightgallery */

.lg-icon {
  font-family: "lg";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1090;
  border: none;
  outline: none;
}

.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  color: #fff;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: "\e095";
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1092;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "\e070";
}

.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #eee;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1090;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-toolbar,
.lg-prev,
.lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

body:not(.lg-from-hash)
  .lg-outer.lg-start-zoom
  .lg-item.lg-complete
  .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1090;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
  margin-left: auto;
  margin-right: auto;
}

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 4px;
  margin-bottom: 5px;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #0c1451;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toogle-thumb {
  background-color: #0d0a0a;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}

.lg-outer .lg-toogle-thumb:after {
  content: "\e1ff";
}

.lg-outer .lg-toogle-thumb:hover {
  color: #fff;
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1090;
  cursor: pointer;
}

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../assets/vimeo-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../assets/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../assets/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../assets/youtube-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../assets/youtube-play.png") no-repeat scroll 0 -60px transparent;
}

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1093;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #0c1451;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging
  .lg-item.lg-complete.lg-zoomable
  .lg-img-wrap,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom
  .lg-item.lg-complete.lg-zoomable
  .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: "\e312";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1090;
  height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #fff;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:hover,
.lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1091;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active .lg-dropdown,
.lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer.lg-dropdown-active #lg-share {
  color: #fff;
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s,
    visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #fff;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}

.lg-outer .lg-dropdown > li:hover a,
.lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: "\e80d";
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e901";
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e904";
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e902";
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e903";
}

.lg-css3.lg-zoom-in .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3);
}

.lg-css3.lg-zoom-in .lg-item.lg-next-slide {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3);
}

.lg-css3.lg-zoom-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-in .lg-item.lg-prev-slide,
.lg-css3.lg-zoom-in .lg-item.lg-next-slide,
.lg-css3.lg-zoom-in .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-zoom-in-big .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}

.lg-css3.lg-zoom-in-big .lg-item.lg-next-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}

.lg-css3.lg-zoom-in-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide,
.lg-css3.lg-zoom-in-big .lg-item.lg-next-slide,
.lg-css3.lg-zoom-in-big .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-zoom-out .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7);
}

.lg-css3.lg-zoom-out .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7);
}

.lg-css3.lg-zoom-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-out .lg-item.lg-prev-slide,
.lg-css3.lg-zoom-out .lg-item.lg-next-slide,
.lg-css3.lg-zoom-out .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-zoom-out-big .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}

.lg-css3.lg-zoom-out-big .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}

.lg-css3.lg-zoom-out-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide,
.lg-css3.lg-zoom-out-big .lg-item.lg-next-slide,
.lg-css3.lg-zoom-out-big .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-zoom-out-in .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}

.lg-css3.lg-zoom-out-in .lg-item.lg-next-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}

.lg-css3.lg-zoom-out-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide,
.lg-css3.lg-zoom-out-in .lg-item.lg-next-slide,
.lg-css3.lg-zoom-out-in .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-zoom-in-out .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}

.lg-css3.lg-zoom-in-out .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}

.lg-css3.lg-zoom-in-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide,
.lg-css3.lg-zoom-in-out .lg-item.lg-next-slide,
.lg-css3.lg-zoom-in-out .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-soft-zoom .lg-item {
  opacity: 0;
}

.lg-css3.lg-soft-zoom .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.lg-css3.lg-soft-zoom .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
}

.lg-css3.lg-soft-zoom .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-soft-zoom .lg-item.lg-prev-slide,
.lg-css3.lg-soft-zoom .lg-item.lg-next-slide,
.lg-css3.lg-soft-zoom .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-scale-up .lg-item {
  opacity: 0;
}

.lg-css3.lg-scale-up .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
}

.lg-css3.lg-scale-up .lg-item.lg-next-slide {
  -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
}

.lg-css3.lg-scale-up .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-scale-up .lg-item.lg-prev-slide,
.lg-css3.lg-scale-up .lg-item.lg-next-slide,
.lg-css3.lg-scale-up .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-circular .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-circular .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide-circular .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.lg-css3.lg-slide-circular .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-circular .lg-item.lg-prev-slide,
.lg-css3.lg-slide-circular .lg-item.lg-next-slide,
.lg-css3.lg-slide-circular .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-circular-up .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
}

.lg-css3.lg-slide-circular-up .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
}

.lg-css3.lg-slide-circular-up .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide,
.lg-css3.lg-slide-circular-up .lg-item.lg-next-slide,
.lg-css3.lg-slide-circular-up .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-circular-down .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
}

.lg-css3.lg-slide-circular-down .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
}

.lg-css3.lg-slide-circular-down .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide,
.lg-css3.lg-slide-circular-down .lg-item.lg-next-slide,
.lg-css3.lg-slide-circular-down .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-circular-vertical .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
}

.lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
}

.lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide,
.lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide,
.lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-circular-vertical-left .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
}

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
}

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide,
.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide,
.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-circular-vertical-down .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
}

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
}

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide,
.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide,
.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-vertical .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-vertical .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.lg-css3.lg-slide-vertical .lg-item.lg-next-slide {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.lg-css3.lg-slide-vertical .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-vertical .lg-item.lg-prev-slide,
.lg-css3.lg-slide-vertical .lg-item.lg-next-slide,
.lg-css3.lg-slide-vertical .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-vertical-growth .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
}

.lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
}

.lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide,
.lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide,
.lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-only .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide {
  -moz-transform: skew(10deg, 0deg);
  -o-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  -webkit-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg);
}

.lg-css3.lg-slide-skew-only .lg-item.lg-next-slide {
  -moz-transform: skew(10deg, 0deg);
  -o-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  -webkit-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg);
}

.lg-css3.lg-slide-skew-only .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}

.lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-only .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-only .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-only-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  transform: skew(-10deg, 0deg);
}

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  transform: skew(-10deg, 0deg);
}

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-only-y .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 10deg);
  -o-transform: skew(0deg, 10deg);
  -ms-transform: skew(0deg, 10deg);
  -webkit-transform: skew(0deg, 10deg);
  transform: skew(0deg, 10deg);
}

.lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 10deg);
  -o-transform: skew(0deg, 10deg);
  -ms-transform: skew(0deg, 10deg);
  -webkit-transform: skew(0deg, 10deg);
  transform: skew(0deg, 10deg);
}

.lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}

.lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -10deg);
  -o-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg);
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -10deg);
  -o-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg);
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew .lg-item.lg-prev-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
}

.lg-css3.lg-slide-skew .lg-item.lg-next-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
}

.lg-css3.lg-slide-skew .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-cross .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-cross .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-cross-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-ver .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -o-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
}

.lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -o-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
}

.lg-css3.lg-slide-skew-ver .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-ver .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-ver-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -o-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
}

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -o-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
}

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-ver-cross .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -o-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
}

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -o-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
}

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -o-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -o-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide,
.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide,
.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-lollipop .lg-item {
  opacity: 0;
}

.lg-css3.lg-lollipop .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-lollipop .lg-item.lg-next-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5);
}

.lg-css3.lg-lollipop .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-lollipop .lg-item.lg-prev-slide,
.lg-css3.lg-lollipop .lg-item.lg-next-slide,
.lg-css3.lg-lollipop .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-lollipop-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5);
}

.lg-css3.lg-lollipop-rev .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-lollipop-rev .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide,
.lg-css3.lg-lollipop-rev .lg-item.lg-next-slide,
.lg-css3.lg-lollipop-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-rotate .lg-item {
  opacity: 0;
}

.lg-css3.lg-rotate .lg-item.lg-prev-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.lg-css3.lg-rotate .lg-item.lg-next-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.lg-css3.lg-rotate .lg-item.lg-current {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}

.lg-css3.lg-rotate .lg-item.lg-prev-slide,
.lg-css3.lg-rotate .lg-item.lg-next-slide,
.lg-css3.lg-rotate .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-rotate-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-rotate-rev .lg-item.lg-prev-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.lg-css3.lg-rotate-rev .lg-item.lg-next-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.lg-css3.lg-rotate-rev .lg-item.lg-current {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}

.lg-css3.lg-rotate-rev .lg-item.lg-prev-slide,
.lg-css3.lg-rotate-rev .lg-item.lg-next-slide,
.lg-css3.lg-rotate-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-tube .lg-item {
  opacity: 0;
}

.lg-css3.lg-tube .lg-item.lg-prev-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
}

.lg-css3.lg-tube .lg-item.lg-next-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
}

.lg-css3.lg-tube .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-tube .lg-item.lg-prev-slide,
.lg-css3.lg-tube .lg-item.lg-next-slide,
.lg-css3.lg-tube .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1090;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-prev-slide,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer .lg-item {
  background: url("../assets/loading.gif") no-repeat scroll center center
    transparent;
  display: none !important;
}

.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1090;
}

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html {
  display: none;
}

.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1090;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-backdrop.in {
  opacity: 0.85;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

/** @section Material Parallax */
.parallax-container {
  position: relative;
  overflow: hidden;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
  z-index: 0;
}

.parallax-content {
  position: relative;
  z-index: 1;
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 101%;
  min-height: 101%;
  max-width: none;
  transform: translate3d(-50%, 0, 0);
}

.parallax-disabled {
  background-size: cover;
  background-position: center;
}

html:not(.ie-11):not(.ios) .parallax-disabled {
  background-attachment: fixed;
}

/** @section Bootstrap Modal */
.ios .modal-open {
  position: fixed;
  width: 100%;
  height: 100%;
}

/** @section Select 2 v4 */
.select2 {
  width: 100% !important;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  display: block;
  cursor: pointer;
  user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"]
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  display: block;
  cursor: pointer;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  position: absolute;
  left: -100000px;
  z-index: 1051;
  display: block;
  overflow: hidden;
  width: 100%;
  border: 1px solid #e9eaef;
  border-radius: 0;
  background-color: transperent;
  box-shadow: 0 0 5px 0 rgba(44, 52, 59, 0.2);
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
  background-color: #ffffff;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #ffffff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single {
  background-color: transperent;
  border-bottom: 1px solid #e9eaef;
  border-radius: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  display: block;
  padding: 6px 14px 6px 4px;
  color: #494f7c;
  font-size: 22px;
  line-height: 37px;
  font-weight: 400;
  min-height: 50px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: 700;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #494f7c;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  right: 5px;
  width: 20px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  pointer-events: none;
  color: #fe9b8e;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b::before {
  position: relative;
  display: inline-block;
  content: "";
  font-family: "Material Design Icons";
  font-size: 18px;
  transform: rotate(0deg);
  transition: 0.1s;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #edeff4;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b::before {
  transform: rotate(180deg);
}

.select2-container--default .select2-selection--multiple {
  background-color: #ffffff;
  border: 1px solid #aaa;
  border-radius: 0.3rem;
  cursor: text;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  list-style: none;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__placeholder,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown {
  border-top: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e9eaef;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled="true"] {
  color: #9b9b9b;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  color: #ffffff;
  background-color: #131f7d;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  color: #ffffff;
  background-color: #0c1451;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
