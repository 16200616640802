/********** Template CSS **********/
:root {
  --primary: #fe9b8e;
  --secondary: #354f8e;
  --light: #eff5f9;
  --dark: #1d2a4d;
}

.btn {
  font-weight: 700;
  transition: 0.5s;
}

.btn:hover {
  -webkit-box-shadow: 0 8px 6px -6px #555555;
  -moz-box-shadow: 0 8px 6px -6px #555555;
  box-shadow: 0 8px 6px -6px #555555;
}

.btn-primary {
  color: #ffffff;
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

a,
.active {
  font-family: "Marcellus", sans-serif !important;
}
.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 0;
  border-radius: 50% 50% 0 0;
  z-index: 999999999999;
  background-color: #f7b1b0 !important;
  border: none;
  cursor: pointer;
}

.back-to-top:hover {
  background-color: #fe9b8e !important;
}

.navbar-wrap {
  position: absolute;
  top: 60px;
  z-index: 9999999999;
  height: 80px;
  background: transparent;
}

.navbar-wrap .container {
  padding: 0;
}

.navbar-wrap .navbar {
  border-radius: 20px;
  padding: 10px;
  margin: 0;
}

.logo {
  height: 60px;
}

.hero-text {
  margin-top: 20%;
}

.btn-outline-light {
  border-color: #606c38 !important;
  color: #606c38 !important;
}

.navbar-light .navbar-nav .nav-link {
  font-family: "Jost", sans-serif;
  position: relative;
  margin-left: 10px;
  padding: 30px 0;
  font-size: 15px;
  font-weight: 700;
  color: var(--dark);
  outline: none;
  transition: 0.5s;
  text-transform: uppercase;
}

.nav-elem {
  font-family: "Jost", sans-serif;
  position: relative;
  color: var(--dark);
  outline: none;
  transition: 0.5s;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  .logo {
    height: 40px;
    width: 60px;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: inline-block;
  padding: 10px 15px;
  font-size: 18px;
  border-radius: 50%;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.back-to-top:hover {
  opacity: 1;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

.nav-elem:hover,
.nav-elem.active {
  color: var(--primary);
}

@media (min-width: 992px) {
  /* .navbar-light .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 5px;
    bottom: 0;
    left: 50%;
    background: var(--primary);
    transition: 0.5s;
  } */

  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
    width: 100%;
    left: 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-left: 0;
    padding: 10px 0;
  }
}

.custom-text {
  color: #606c38;
}

.hero-header {
  background: url(../assets/bot.jpg) top right no-repeat;
  background-size: cover;
  /* background-color: #fcedea; */
  height: 100vh;
}

.service-item {
  position: relative;
  height: 350px;
  padding: 0 30px;
  transition: 0.5s;
}

.about {
  font-family: "Great Vibes", sans-serif;
  font-size: 2rem;
}

.services-wrap {
  height: 380px !important;
}

.service-item .service-icon {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  margin-top: 10px;
  /* transform: rotate(-14deg); */
}

.service-item .service-icon img {
  /* transform: rotate(15deg); */
  height: 80px;
}

.text-wrap {
  position: relative;
  z-index: 4;
}

.testimonial-wrap::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 40%;
  background-image: url("../assets/spa-background-with-a-space-for-a-text-MM3Z8HQ.jpg");
  background-position: 0px -450px;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.11;
  z-index: 1;
}

.container-top {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-top h1 {
  margin-top: 135px;
}

.background-image-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 400px;
  background-image: url("../assets/flowers-and-eucaaliptus-composition-M9PL2FC-removebg-preview.png");
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat;
  background-size: cover;
}

.service-item a.btn {
  position: absolute;
  width: 60px;
  bottom: -48px;
  left: 50%;
  margin-left: -30px;
  opacity: 0;
}

.service-item:hover a.btn {
  bottom: -24px;
  opacity: 1;
}

.price-carousel::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background: var(--primary);
  border-radius: 8px 8px 50% 50%;
  z-index: -1;
}

.price-carousel .slick-slide {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  margin-top: 35px;
}

.price-carousel .owl-nav .owl-prev,
.price-carousel .owl-nav .owl-next {
  position: relative;
  margin: 0 5px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #ffffff;
  font-size: 22px;
  border-radius: 45px;
  transition: 0.5s;
}

.price-carousel .owl-nav .owl-prev:hover,
.price-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

@media (min-width: 576px) {
  .team-item .row {
    height: 350px;
  }
}

.team-carousel .owl-nav {
  position: absolute;
  padding: 0 45px;
  width: 100%;
  height: 45px;
  top: calc(50% - 22.5px);
  left: 0;
  display: flex;
  justify-content: space-between;
}

.team-carousel .owl-nav .owl-prev,
.team-carousel .owl-nav .owl-next {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary);
  border-radius: 45px;
  font-size: 22px;
  transition: 0.5s;
}

.team-carousel .owl-nav .owl-prev:hover,
.team-carousel .owl-nav .owl-next:hover {
  background: var(--dark);
}

.testimonial-carousel .owl-dots {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  background: var(--light);
  border: 2px solid var(--primary);
  border-radius: 20px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  width: 40px;
  height: 40px;
  background: var(--primary);
}

.testimonial-carousel .owl-item img {
  width: 150px;
  height: 150px;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  line-height: 1.5em;
}

.footer-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.drpdwn-wrap {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 10px !important;
}

@media (max-width: 992px) {
  .drpdwn-wrap {
    flex-direction: column !important;
    gap: 0 !important;
  }
  .dropdown-item {
    white-space: break-spaces !important;
  }
}

.contact-button {
  background-color: #f7b1b0 !important;
  padding: 12px 12px;
  cursor: pointer;
  border: none;
  /* opacity: 0.8; */
  position: fixed !important;
  bottom: 23px;
  left: 28px;
  width: 180px;
  border-radius: 10px;
  z-index: 999999999999;
}

.contact-button:hover {
  background-color: #fe9b8e !important;
}

.slick-prev,
.slick-next {
  z-index: 9999 !important;
  bottom: -25px !important;
  width: 40px !important;
  height: 40px !important;
  background-color: #fe9b8e !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  top: 100% !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 34px !important;
  color: white !important;
  margin-bottom: -5px !important;
}

.slick-prev {
  left: 44% !important;
}

.slick-next {
  right: 44% !important;
}

@media (max-width: 992px) {
  .slick-prev,
  .slick-next {
    width: 24px !important;
    height: 24px !important;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 22px !important;
    margin-top: -2.5px !important;
  }

  .slick-prev {
    left: 40% !important;
  }

  .slick-next {
    right: 40% !important;
  }
}

.blink-border {
  animation: zoom-animation 1s infinite;
}

@keyframes zoom-animation {
  0%,
  100% {
    transform: scale(1); /* Original size */
  }
  50% {
    transform: scale(1.1); /* Zoomed-in size */
  }
}

.medical-clinic {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.m-dropdown {
  margin-left: -75% !important;
}

.dropdown-item {
  font-size: 12px !important;
}

h5 {
  span {
    color: #848e9e;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}


.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: 8px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.multi-level {
  /* margin-left: -20px !important; */
  width: 285px;
}

.multi-level li {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.multi-level-wrap {
  display: flex;
}

.menu-section {
  flex: 1;
  padding: 5px;
  width: 200px;
}

@media (max-width: 992px) {
  .logo {
    height: 45px;
  }
}

.result-shown {
  position: absolute;
  right: 0;
  top: 100%;
  max-height: 300px;
  overflow: auto;
  max-width: 300px;
}

.result-shown li {
  cursor: pointer;
}

.search-icon {
  font-size: 20px;
  cursor: pointer;
}

